import {createSlice} from "@reduxjs/toolkit";

const initialState=""
export const impersonationSlice = createSlice({
  name: 'impersonation',
  initialState: { impersonatedUser: initialState},
  reducers: {
    setImpersonation: (state, action)=>{
      state.impersonatedUser=action.payload
    },
    reset: (state)=>{
      state.impersonatedUser=""
    }
  }
})
// Export the reducer function
export const { setImpersonation, reset } = impersonationSlice.actions
export default impersonationSlice.reducer