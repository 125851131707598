import React, {useEffect, useState} from "react";
import {IssueCardComponent} from "./IssueCard";
import './jira-board.css'
import clock from './clock.png'
import {api} from "../../../api/boardsApi";
import {Issues} from "./types";
import {Flex, Input} from '@chakra-ui/react'
import {useAppSelector} from "../../../stateManagement/hooks";
interface IssuesProps  {
    selectedBoard: string,
    selectedSprint: string,
    assigneeFilter: string|null,
    setPossibleAssignees: (assignees: string[]) => void
    setAssignee: (newAssignee: string) =>void
}

export interface API_Response {
    JIRA_BASE_URL:         string;
    columns:               string[];
    columns_size:          number;
    issues:                object;
    unique_assignees_list: string;
    remaining_estimate:    Record<string, string>;
}



export default function BoardIssues(props: IssuesProps){
         // {# Renders serverside ajax content and dumps it in a div. #}
    const [columns, setColumns] = useState<string[]>([]);
    const [colSize, setColSize] = useState<number>(4);
    const [issues, setIssues] = useState<Issues>({data: {}})
    const [remainingEstimate, setRemainingEstimate] = useState<Record<string, string>>()
    const [search, setSearch] = useState("")
    let refresh = useAppSelector(state => state.refresh.jiraBoardrefresher)

    useEffect(() => {
        const sprint = props.selectedSprint? props.selectedSprint : "False"
        const assigneeFilter = props.assigneeFilter? props.assigneeFilter : ""
        api.getIssues(props.selectedBoard, sprint, assigneeFilter).then(value =>{
        let api_response: API_Response = value.data;
        setColumns(api_response.columns);
        setColSize(api_response.columns_size);

        let issues_local:Record<string, any>  = api_response.issues;

        setIssues({data: issues_local});
        setRemainingEstimate(api_response.remaining_estimate)
        props.setPossibleAssignees(JSON.parse(api_response.unique_assignees_list));
        }
        )
    }, [props.selectedBoard, props.selectedSprint, props.assigneeFilter, refresh]);
         return (<div id="ajax-content">

             <div id="sprint-board">

                 <div className="container-fluid padding-0">
                    <StorySearchBar search={search} setSearch={setSearch}/>
                     {/*Header*/}
                     <div className="row m-0">
                         <ul id="columns" className="w-100">

                             {columns.filter(value => issues.data[value].length > 0).map((column, index) =>
                             <div className={"col-12 col-sm-5 col-md-" + colSize + " padding-5"} key={index}>
                                 <ColumnHeader columnName={column} remaining_estimate={remainingEstimate ? remainingEstimate[column]: "0"} key={index}/>

                                 <li className="col-12 col-sm-12 col-md-12" key={column}>
                                     {issues?.data[column].map(issue =>
                                         issue.summary_full.toLowerCase().includes(search.toLowerCase()) || issue.key.toLowerCase().includes(search.toLowerCase())?
                                         <IssueCardComponent  issue={issue} key={issue.key} /> : <></>
                                     )}

                                 </li>
                             </div>
                              )}
                         </ul>
                     </div>
                 </div>
             </div>
         </div>)
}

interface ColumnProps{
    columnName: string,
    remaining_estimate: string
}
function ColumnHeader(props:ColumnProps){

    return (<li className="col-11 jira-col-header" >
        <Flex justifyContent={"space-between"} alignItems={"center"} >
            <div>
            <h2 className=" text-left">{props.columnName}</h2>
                </div>
            <div className="text-right header-text-right"
                >
                <img src={clock} title="Remaining work days" alt={"clock"}
                     className="remaining-time-icon"/>

            <div className="remaining-estimate-time">
                <strong>{props.remaining_estimate}</strong>
            </div>
                </div>
        </Flex>
    </li>)
}
interface SearchProps{
    search: string,
    setSearch: (search: string) => void
}
function StorySearchBar(props: SearchProps) {

    return (
        <div className="row m-0">
            <div className="col-sm-12 text-center">
                <div id="filter-assignee-name-wrapper">
                    <Input className={"text form-control search-bar"} placeholder={"Search Story"}
                    value={props.search} onChange={event => props.setSearch(event.target.value)}/>

                </div>
            </div>
        </div>)
}
