import React from "react";
import {Week} from "../types";
import {TableHeader} from "./TableHeader";
import {TableBody} from "./TableBody";

interface WeekTableComponentProps {
    data: Week
    detailed:boolean
    JIRA_URL: string,
    index: number
}
export function WeekTableComponent(props: WeekTableComponentProps) {

    const detailed = props.detailed;
    const dates: Date[] = props.data.week_dates.map(value => new Date(value));

    return (

        <table className="col-12" >
            {/*<h4>{JSON.stringify(props.data.bookings)}</h4>*/}
            <TableHeader  dates={dates} showDaysOfTheWeek={detailed || props.index ===0}
                         />
            <TableBody detailed={detailed} dates={dates} bookings={props.data.bookings}
                        time_off={props.data.time_off} total_daily_hours={props.data.total_daily_hours}
                       total_overtime_hours={props.data.total_overtime_hours}
                       total_weekly_hours={props.data.total_weekly_hours} JIRA_BASE_URL={props.JIRA_URL}
                       expected_hours={props.data.expected_hours}

            />

        </table>
    )
}