import React from "react";
import ChakraModalFromConfig, {FormConfig} from "../../common_components/ModalFormFromConfig";
import {api} from "../../api/timesheetAPI";
import {useAppDispatch, useAppSelector} from "../../stateManagement/hooks";
import {jiraBoardrefreshNofilters, setTimeTableLoading, timetableRefresh} from "../../stateManagement/refreshers";
import {showSwal} from "../../common_components/Alert";
import {HourCategories, LocationToDefaultCategory} from "./hourCategories";
import {Backlog} from "../JiraContent/Board/types";
import moment from "moment/moment";

interface AddWorkLogModalProps{
    isOpen: boolean
    onClose: ()=>void
    initial: Backlog
}
function AddWorklogUsingJiraCards(props: AddWorkLogModalProps) {

    const user = useAppSelector(state => state.user.user)
    const dispatch = useAppDispatch()
    const impersonated = useAppSelector(state => state.impersonation.impersonatedUser)

    const handleSubmit = (formData: Record<string, string | boolean | number | [string, string]>) => {
        dispatch(setTimeTableLoading(true))
        api.addFromJiraCards({...formData, impersonate: impersonated}).then(value => {
            dispatch(timetableRefresh())
            dispatch(jiraBoardrefreshNofilters())
        }).catch(error =>{
        console.log(error.response.data)
            showSwal("failed", error.response.data)
            dispatch(setTimeTableLoading(false))
        })

    };

    const formConfig: FormConfig[] = [
        {label: "Story Jira ID", name: "jira_id", type: "text", required: true, disabled:true},
        {
            label: "Hours Category", name: "category", type: "dropdown", required: true,
            options: HourCategories
        },
        {label: "Log Period", name: "dates", required:true, type: "dateRange"},
        {label: "Time Spent per day", name: "time_logged", required:true, type: "number", postText: "(hours)"},
        {label: "Original Estimate", name: "originalEstimate", type: "number",disabled:true, postText: "(hours)"},
        {label: "Time Spent", name: "timeSpent", type: "number",  disabled:true, postText: "(hours)"},
        {label: "Remaining Estimate", name: "remaining_estimate", type: "number", secured:true, postText: "(hours)"},
        {label: "Comment", name: "comment", type: "text"},
        {label: "Performance Incentive", name: "overtime", type: "boolean"}
    ];

    const initialValues = {
        jira_id: props.initial.key,
        category: LocationToDefaultCategory[user.location],
        dates: [moment(new Date()).format("YYYY-MM-DD"), ""] as [string, string],
        overtime: false,
        remaining_estimate: props.initial.remainingEstimate.seconds /3600,
        timeSpent: props.initial.timeSpent.seconds /3600,
        originalEstimate: props.initial.originalEstimate.seconds /3600
    }

    return (<>

            <ChakraModalFromConfig isOpen={props.isOpen} onClose={props.onClose} onSubmit={handleSubmit} formConfig={formConfig}
                                   title={` ${props.initial.summary_full}`} initialValues={initialValues}/>
        </>
    );
};

export default AddWorklogUsingJiraCards;

