import React, { useState} from "react";
import BoardIssues from "./Board/JiraBoard";
import './Board/jira-board.css'
import InfoNotificationComponent from "../../common_components/NotificationComponent";
import {api} from '../../api/boardsApi'
import AddWorklogUsingJira from "../WorklogModals/AddWorklogUsingJira";
import {BoardSelectorComponent, SprintSelectorComponent, AssigneeSelectorComponent, Sprint} from "./Selectors";
import {useAppSelector} from "../../stateManagement/hooks";
import {Center, Divider, Flex, Spinner} from "@chakra-ui/react";

export default function JiraContent() {
    const [selectedBoard, setSelectedBoard] = useState<string >("")
    const [sprint, setSprint] = useState<Sprint | null>(null)
    const [assignee, setAssignee] = useState<string | null>(null)
    const [possibleSprints, setPossibleSprints] = useState<Sprint[]>([])
    const [possibleAssignees, setPossibleAssignees] = useState<string[]>([])
    const userHasJira = useAppSelector(state => state.user.user.has_jira)
    const currentUserName = useAppSelector(state => state.user.user.name)
    const [loading, setLoading] = useState<boolean>(false)

    function onBoardChange(board_id: any) {
        setLoading(true)
        api.getSprints(board_id).then(value => {
            let possibles = value.data.reverse().concat([{id: "Backlog", name: "Backlog"}])
            setPossibleSprints(possibles)
            if (possibles.length > 0) setSprint(possibles[0]);
            else setSprint(null)
            setSelectedBoard(board_id);
            setLoading(false)
        })
    }
    function onSetPossibleAssignees(assignees_new: string[]) {
        let assigness = []
        for (let i = 0; i < assignees_new.length; i++) {
            assigness.push(assignees_new[i])
        }
        setPossibleAssignees(assigness)
    }
    return (<>
                {!userHasJira &&
                <InfoNotificationComponent/>}
            <section className={"bg-white"}>
                <Flex justifyContent={"flex-start"} alignItems={"center"} minHeight={"50px"}>


                <div className="w-18rem ml-3">
                    <BoardSelectorComponent selectedBoard={selectedBoard} setSelectedBoard={onBoardChange}/>

                </div>


                <div className=" ml-2 w-18rem">
                    <SprintSelectorComponent sprints={possibleSprints} selectedSprint={sprint} setSprint={setSprint}/>
                </div>

                <div className="ml-2 w-18rem">
                    <AssigneeSelectorComponent possibleAssignees={possibleAssignees} setAssignee={setAssignee} assignee={assignee} ogname={currentUserName}/>
                </div>

                <div  className="ml-2 w-18rem">
                    <RefreshButton/>
                    <PopUpButtons/>
                </div>
            </Flex>
                <Divider style={{margin:"0px"}} colorScheme={"black"} size={"10px"}/>
            {(selectedBoard && (sprint !== null) && !loading) ?
                <BoardIssues selectedBoard={selectedBoard} assigneeFilter={assignee} setPossibleAssignees={onSetPossibleAssignees}
                             selectedSprint={sprint ? String(sprint.id) : "False"}
                            setAssignee={setAssignee}
                />:
                <div className={"bg-white"} style={{minHeight: "50vh"}}>

                </div>
            }
                <Divider colorScheme={"black"} />
            { loading &&
                <div style={{height: "50vh"}}>
                <Center>
            <Spinner size={"xl"}/>
                    </Center>
                </div>
            }

</section>
        </>
    )
}


function RefreshButton() {
    return (<button className="aui-button"
        // onClick="refreshBoard()"
    >
				    	<span className="aui-icon aui-icon-small aui-iconfont-build">

			    		</span>
    </button>)
}

function PopUpButtons() {
    return (<>

        <a className="add-work-log-jira fancybox.ajax"
            // onClick="updateAddWorkAdminIssueHref()"
           href="#">
            <AddWorklogUsingJira/>

        </a>

    </>)
}
