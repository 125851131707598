import {createSlice} from "@reduxjs/toolkit";
import {Preferences} from "../types";

interface User {
  name: string,
  email: string,
  user_can_manage: boolean,
  avatar_url: string,
  has_jira: boolean,
  location: string
}

const userInitialState: User= {name: "", email: "", user_can_manage:false, avatar_url: "", has_jira: false, location:"BG"};
const preferencesInitialState: Preferences =  {favouriteTeams: [], favourites: [], defaultBoard: {label: "", value: ""}, showTeams: false}
const currentFavouritesInitialState: string[] = []

export const userSlice = createSlice({
  name: 'user',
  initialState: { user: userInitialState, token: "", preferences: preferencesInitialState, currentFavs:currentFavouritesInitialState},
  reducers: {
    login: (state, action)=>{
      state.user=action.payload
      // state.user.user_can_manage = false
    },
    logout: (state)=>{
      state.token=""
      state.user = userInitialState
    },
    initialLoadUserPreferences:(state, action)=>{
     state.preferences = action.payload;
     state.currentFavs = state.preferences.favourites;
     // state.user.user_can_manage = false
    },
    addFavouriteClientSide: (state, action)=>{
      if (!state.currentFavs.find((value)=>action.payload === value )){
      state.currentFavs.push(action.payload)
      }

    },
    removeFavouriteClientSide: (state, action)=>{
      const index = state.currentFavs.indexOf(action.payload)
      if (index > -1) state.currentFavs.splice(index, 1)
    },
    updatePreferences: (state, action)=>{
      state.preferences = action.payload;
      // state.user.user_can_manage = false
}
  }
})
// Export the reducer function
export const { login, logout , addFavouriteClientSide, removeFavouriteClientSide,
  initialLoadUserPreferences,
  updatePreferences} = userSlice.actions
export default userSlice.reducer