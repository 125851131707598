import React from "react";
import moment from "moment";

interface TableHeaderProps {
    dates: Date[],
    showDaysOfTheWeek: boolean

}

export function TableHeader(props: TableHeaderProps) {
    const days_of_week = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    const today = new Date()
    function getRowClass(week_day: Date, idx_day: number) {
        let className = props.showDaysOfTheWeek ? "" : " expanded "

        if (week_day.getDate() === today.getDate() &&  week_day.getMonth() === today.getMonth())
        {
            className += " today "
        } else if (week_day.getUTCDay() === 6 || week_day.getUTCDay() === 0) {
            className += " non-business-day "
        }
        return className
    }
    function getWeek() {
        return moment(props.dates[0]).week()
    }

    return (
        <thead>
        <tr key={"dates"}>

            <th key={"key"} className="key" rowSpan={2}>
                Key
            </th>
            <th key={"summary"} className="summary summary-username" rowSpan={2}>
                Summary wk{getWeek()}
            </th>
            {props.dates.map((week_day, idx_day) =>
                <th key={idx_day} className={getRowClass(week_day, idx_day)}>
                        <span className="day">
						{week_day.getDate()}
					</span>
                </th>
            )}
        </tr>

        {props.showDaysOfTheWeek &&
            <tr key={"weekDays"}>
                {props.dates.map((week_day, idx_day) =>
                    <th key={idx_day} className={getRowClass(week_day, idx_day)}>
                        <span className="dow">
						{days_of_week[idx_day]}
					</span>
                    </th>)
                }
            </tr>
        }
        </thead>)
}
