import axiosInstance, {headers} from './axiosInstance';
import {
    ADD_FAVOURITES_SERVICE_URL,
    CURRENT_USER_URL,
    LOGIN_URL, LOGOUT_URL,
    PREFERENCES_SERVICE_URL,
    SEARCH_USER_URL,
    TEAMS_URL
} from './config';

export const api = {
    // async getAll(requestModel) {
    //   return axiosInstance.get(GET_ALL_EMPLOYEES_URL, {
    //     params: {
    //       pageNumber:
    //         Object.keys(requestModel).length > 0 ? requestModel.pageNumber : null,
    //       pageSize:
    //         Object.keys(requestModel).length > 0 ? requestModel.pageSize : null,
    //       departmentId:
    //         Object.keys(requestModel).length > 0
    //           ? requestModel.departmentId
    //           : null,
    //     },
    //   });
    // },


    async addFavourite(fav: string) {

        return axiosInstance.post(`${ADD_FAVOURITES_SERVICE_URL}`, {new_favourite: fav}, {headers: headers});
    },
    async removeFavourite(fav: string) {

        return axiosInstance.delete(`${ADD_FAVOURITES_SERVICE_URL}?to_delete=${fav}`,  {headers: headers});
    },
    async searchUser(searchTerm: string) {
        return axiosInstance.get(`${SEARCH_USER_URL}?q=${searchTerm}`, {headers: headers});
    },
    async login(formData:any) {
        return axiosInstance.post(`${LOGIN_URL}`, formData,  {headers: headers});
    },
    async logout() {
        return axiosInstance.get(`${`${LOGOUT_URL}`}`,  {headers: headers});
    },
    async me(){
        return axiosInstance.get(`${CURRENT_USER_URL}`, {headers:headers})
    },
    async getAllTeams(){
        return axiosInstance.get(`${TEAMS_URL}`, {headers:headers})
    },
    async getTeam(name:string){
        return axiosInstance.get(`${TEAMS_URL}/${name}`, {headers:headers})
    },
    async getPreferences(){
        return axiosInstance.get(`${PREFERENCES_SERVICE_URL}`, {headers:headers})
    },
    async updatePreferences(formData: any){
        return axiosInstance.post(`${PREFERENCES_SERVICE_URL}`, formData, {headers:headers})
    }
};
