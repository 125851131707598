import { configureStore } from '@reduxjs/toolkit'
import {impersonationSlice} from "./impersonation";
import {userSlice} from "./userSlice";
import {refresherSlice} from "./refreshers";
// ...

export const store = configureStore({
  reducer: {
    impersonation: impersonationSlice.reducer,
    user: userSlice.reducer,
    refresh: refresherSlice.reducer
  },
})
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch