import Select from "@atlaskit/select";
import React, {useEffect} from "react";

export interface AssigneeSelectorProps {
    possibleAssignees: string[],
    setAssignee: (value: string | null) => void,
    assignee: string | null,
    ogname: string
}

export function AssigneeSelectorComponent(props: AssigneeSelectorProps) {

    let vals = [{value: "", label: "<All Assignees>"},
        {value: props.ogname, label: "<Assigned To Me>"}].concat(
            props.possibleAssignees.map(value => { return {value: value, label: value}})
    )
    const defaultAssignee = props.assignee && vals.find(value => value.value === props.assignee)
        ? {
        value: props.assignee,
        label: props.assignee
    } : vals[0]
    useEffect(() => {

    }, []);
 return (<>
            <Select
                inputId="assignee-select"
                className="single-select  select2-hidden-accessible  "
                classNamePrefix="react-select"
                value={defaultAssignee}
                options={vals}
                placeholder="Choose a sprint"
                spacing={"compact"}
                appearance={"default"}
                onChange={(newValue: any) => {

                    if (newValue) {
                        props.setAssignee(newValue.value)
                    }

                }}
            />
        </>
    )
}
