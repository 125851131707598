import React from 'react';
import './App.css';
import MainPage from "./pages/MainPage";
import {ChakraProvider} from "@chakra-ui/react";
import {Provider} from "react-redux";
import {store} from "./stateManagement/store";
function App() {
  return (

      <Provider store={store}>
  <ChakraProvider resetCSS={false} >
    <MainPage/>

    </ChakraProvider>
      </Provider>
  );
}
export default App;
