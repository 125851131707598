import TextPageComponent from "../common_components/TextPage";

export function TermsOfServiceComponent (){


    return <TextPageComponent title={"Terms Of Service"}>
        <div  className={"px-3"} dangerouslySetInnerHTML={{__html: termsString}}></div>
    </TextPageComponent>
}

const termsString:string ='<p style="text-align:center;">\n' +
    '  <span style="color:rgb(13, 13, 13);">\n' +
    '    <strong>TimeBox</strong>\n' +
    '  </span>\n' +
    '  <span style="color:rgb(13, 13, 13);">\n' +
    '    <strong>\n' +
    '      Internal\n' +
    '    </strong>\n' +
    '  </span>\n' +
    '  <span style="color:rgb(13, 13, 13);">\n' +
    '    <strong>Application Terms of Service</strong>\n' +
    '  </span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <h5><span style="color:rgb(13, 13, 13);">1. Acceptance of Terms:</span></h5>\n' +
    '</p>\n' +
    '<p>Time Box is created by the professionals of Strypes EOOD and solely owned by Strypes EOOD.\n' +
    '  <span style="color:rgb(13, 13, 13);">By accessing or using the TimeBox internal application ("Service"), you undertake to comply with and be bound by these Terms of Service.' +
    '<h5>2. Description of Service:</h5></span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:rgb(13, 13, 13);">TimeBox is an internal application designed exclusively for Strypes employees to facilitate the management and recording of worked hours on projects within the company. The Service allows employees to track and record their worked hours, collaborate on projects, and integrate with internal systems.</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:rgb(13, 13, 13);">*For the purposes of this document the definition of “Strypes employee” shall inclide employees on labor contract with Strypes EOOD, contractors on civil contract, specialists provided by Strypes’\n' +
    '  </span>\n' +
    '  <span style="color:rgb(13, 13, 13);">partners</span>\n' +
    '  <span style="color:rgb(13, 13, 13);">\n' +
    '    for specific project execution, employees of Strypes’ Affiliates.</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:rgb(13, 13, 13);"><h5>3. User Accounts:</h5></span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:rgb(13, 13, 13);">a. Onboarding: Every Employee shall receive a TimeBox account as part of the onboarding after signing a relevant contract and receiving work(Strypes) email.</span>\n' +
    '  <span style="color:rgb(13, 13, 13);">To use the Service an employee can choose between authenticating with a Strypes Google Account or authenticating with an Atlassian Jira account. Both methods are implementing the SSO authentication standard.\n' +
    '  </span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:rgb(13, 13, 13);">b. Account Security: An Employee is responsible for maintaining the security of his account credentials. The owners of the Service should be notified immediately in case of any unauthorized use or security breach.</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:rgb(13, 13, 13);"><h5>4. Use of the Service:</h5></span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:rgb(13, 13, 13);">a. Internal Use Only: The Service is intended for use solely by Strypes employees and it’s Affiliates(</span>\n' +
    '  “Affiliates” means any entity that is directly or indirectly more than 50% owned by a party or is Controlled by a Party. Controlled means the possession, directly or indirectly, of the power to direct the management and policies of such an entity.<span style="color:rgb(13, 13, 13);">) and may only be used for internal, company-related purposes. It is strictly forbidden to share any part of the information contained in TimeBox with third parties</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:rgb(13, 13, 13);">b. Compliance: You agree to use the Service in compliance with all applicable Strypes policies and procedures including relevant legislation.</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:rgb(13, 13, 13);"><h5>5. Privacy:</h5></span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:rgb(13, 13, 13);">Your use of the Service is also governed by our Privacy Policy. Please review the Privacy Policy to understand how we collect, use, and protect your information within the company\'s internal context.</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:rgb(13, 13, 13);"><h5>6. Intellectual Property:</h5></span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:rgb(13, 13, 13);">a. Ownership: All content and materials on the Service, including but not limited to text, graphics, data,&nbsp; logos, and software, are the property of Strypes.</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:rgb(13, 13, 13);">b. License: Subject to these Terms, Strypes grants you a limited, non-exclusive, and non-transferable license to use the Service for its intended internal purposes.</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:rgb(13, 13, 13);"><h5>7. Third-Party Services:</h5></span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:rgb(13, 13, 13);">The Service may integrate with internal Strypes systems for authentication and data retrieval. Your use of these systems is subject to Strypes\' internal policies.</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:rgb(13, 13, 13);"><h5>8. Termination:</h5></span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:rgb(13, 13, 13);">Strypes reserves the right to terminate or suspend your account and access to the Service at its sole discretion, with or without cause, and without notice.</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:rgb(13, 13, 13);"><h5>9. Changes to Terms:</h5></span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:rgb(13, 13, 13);">Strypes may update these Terms of Service at any time. Your continued use of the Service after the effective date of any changes constitutes acceptance of the modified terms.</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:rgb(13, 13, 13);"><h5>10. Disclaimers and Limitations of Liability:</h5></span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:rgb(13, 13, 13);">a. No Warranty: The Service is provided "as is" and "as available" without any warranties.</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:rgb(13, 13, 13);">b.</span>\n' +
    '  <span style="color:rgb(13, 13, 13);">\n' +
    '    Limitation of Liability:</span>\n' +
    '  <span style="color:rgb(13, 13, 13);">&nbsp; Strypes shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of the Service within the internal Strypes context.</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:rgb(13, 13, 13);"><h5>11. Governing Law:</h5></span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:rgb(13, 13, 13);">These Terms of Service are governed by and construed in accordance with the internal policies of Strypes and applicable local legislation.</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:rgb(13, 13, 13);"><h5>12. Contact Information:</h5></span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:rgb(13, 13, 13);">For questions or concerns about these Terms of Service, please contact us at timebox.support@strypes.eu.</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:rgb(13, 13, 13);">By using the TimeBox internal application, you acknowledge that you have read, understood, and agree to these Terms of Service.</span>\n' +
    '</p>'


const policyString = '<p style="text-align:center;">\n' +
    '  <span style="color:#0d0d0d;">TimeBox Web Application Privacy Policy</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:#0d0d0d;">Thank you for choosing TimeBox, a web application designed to help users manage and book their worked hours on projects in Strypes EOOD. Strypes EOOD is the owner of TimeBox and the company\n' +
    '  </span>respects the privacy of the persons whose personal data we process, hereinafter collectively referred as “Controller” or “Time Box”. We, therefore, strive to comply with all applicable laws regarding privacy protection and the security of personal data. We, therefore, use the utmost care when processing and securing personal data.</p>\n' +
    '<p>\n' +
    '  <span style="color:#0d0d0d;">&nbsp;This Privacy Policy outlines how TimeBox, which uses Google accounts, Atlassian OAuth for authentication, and retrieves employee data from BambooHR, collects, uses, shares, and protects your personal information. By using the TimeBox web application, you agree to the terms of this Privacy Policy.</span>\n' +
    '</p>\n' +
    '<p>Time Box (Strypes EOOD) is in the case the controller. In this privacy policy, we will inform you about, among other things, what personal data we collect and use concerning you and for what purpose.&nbsp;</p>\n' +
    '<p>\n' +
    '  <strong>Our company details are:</strong>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:#252524;">Strypes EOOD</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:#252524;">10A Maystor Aleksi Rilets Str.</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:#252524;">Sofia, 1618</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:#252524;">Bulgaria</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:#252524;">M: +359 893516833</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:#252524;">e-mail:\n' +
    '  </span>\n' +
    '  <a target="_blank" href="mailto:hello@strypes.eu">hello@strypes.eu</a>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <strong>Scope</strong>\n' +
    '</p>\n' +
    '<p>This Policy applies to all the Personal Data collected, maintained and processed in any way via TimeBox application.</p>\n' +
    '<p>\n' +
    '  <strong>Legal Basis for Processing</strong>\n' +
    '</p>\n' +
    '<p>The legal basis for the Processing is:&nbsp;</p>\n' +
    '<p>a) the performance of the (employment) agreement to which the Data Subjects is a party;&nbsp;</p>\n' +
    '<p>b) the legitimate interest of the Controller;&nbsp;</p>\n' +
    '<p>c) a legal obligation of the Controller or of a third party;</p>\n' +
    '<p>d) performance of a task carried out in the public interest or in the exercise of official authority;</p>\n' +
    '<p>e) a vital interest of the Data Subject,&nbsp;</p>\n' +
    '<p>or - only if a), b), c), d) or e) does not apply - f) the unambiguous consent granted by the Data Subject.&nbsp;</p>\n' +
    '<p>\n' +
    '  <span style="color:#0d0d0d;">\n' +
    '    <strong>&nbsp;Information We Collect:</strong>\n' +
    '  </span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:#0d0d0d;">a. Google Account Information: When you authenticate through Google, we collect and store your basic Google account information, including your email address and profile picture.</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:#0d0d0d;">b. Atlassian Account Information: When you authenticate through Atlassian OAuth, we collect and store relevant account information, such as your email address and profile details.</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:#0d0d0d;">c. BambooHR Employee Data: TimeBox integrates with BambooHR to retrieve employee data, including names, departments, and divisions.</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:#0d0d0d;">d. Worked Hours Data: TimeBox allows you to input and track your worked hours on various projects. This includes project details, task descriptions, and the amount of time spent.</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:#0d0d0d;">e. Usage Information: We collect information about how you interact with TimeBox, including log files, device information, and usage patterns. This may include IP addresses, browser type, and pages visited.</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:#0d0d0d;">\n' +
    '    <strong>How We Use Your Information:</strong>\n' +
    '  </span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:#0d0d0d;">a. Providing Services: We use your information to provide you with access to the TimeBox web application, allowing you to manage and book your worked hours on projects.</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:#0d0d0d;">b. Authentication: Your Google and Atlassian account information is used solely for the purpose of authentication and is not shared with third parties.</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:#0d0d0d;">c. Employee Data Integration: BambooHR employee data is used to streamline the process of associating employees with projects and tasks, ensuring accurate tracking and reporting.</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:#0d0d0d;">d. Improving User Experience: We analyze usage patterns and feedback to enhance and improve the functionality of TimeBox, ensuring a better user experience.</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:#0d0d0d;">e. Communication: We may use your email address to send important updates, notifications, or information related to your TimeBox account and services.</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:#0d0d0d;">\n' +
    '    <strong>Data Security</strong>\n' +
    '  </span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:#0d0d0d;">TimeBox does everything necessary to protect your personal data. TimeBox employs industry-standard encryption techniques to protect your data during transmission and storage. To this end, Strypes EOOD has developed an information security policy based on ISO27001. The Information Security Policy on an yearbais by an independent certification authority.&nbsp;</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:#0d0d0d;">In addition to technical protection, people are also important. Access to personal information and BambooHR employee data is restricted to authorized personnel who need the information to perform their job duties. All our employees have a confidentiality clause in their labor contract. Strypes also invests significantly in raising employee awareness in the area of information security and data privacy.</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:#0d0d0d;">\n' +
    '    <strong>&nbsp;Information Sharing</strong>\n' +
    '  </span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:#0d0d0d;">a. Project Collaborators: In the context of a collaborative project, your worked hours data may be shared with other users involved in the same project.</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:#0d0d0d;">b. Google, Atlassian, and BambooHR Data: Your account information and BambooHR employee data are used for internal purposes and are not shared with third parties.</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:#0d0d0d;">c. Legal Compliance: We may disclose your information in response to legal requests or when required to comply with applicable laws and regulations.</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:#0d0d0d;">\n' +
    '    <strong>Third-Party Services</strong>\n' +
    '  </span>\n' +
    '  <span style="color:#0d0d0d;">TimeBox integrates with Google accounts, Atlassian OAuth, and BambooHR. Please be aware that these services have their own privacy policies, and we encourage you to review them.</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <strong>Transfer of Personal Data to Countries Outside the European Union</strong>\n' +
    '</p>\n' +
    '<p>The Controller will not transfer Personal Data to a company or establishment in a country outside the European Economic Area, unless at least one of the following conditions is met:&nbsp;</p>\n' +
    '<p>a. an adequacy decision has been taken by the European Commission to ensure an adequate level of protection for the third country in question;&nbsp;</p>\n' +
    '<p>b. an agreement has been signed with the company or organization in question in accordance with the standard contractual clauses established by the European Commission; there is an approved code of conduct, certification / or binding company rules established by a Personal Data Authority that is comparable to the supervisor within the EU, or a different organization established based on agreements between the European Union and third countries, such as the United States;</p>\n' +
    '<p>&nbsp;c. the Controller has the express consent of the Data Subject.</p>\n' +
    '<p>&nbsp;d. the transfer is necessary for the performance of an (employment) agreement between the Data Subject and the Controller or for the implementation of pre-contractual measures taken at the request of the Data Subject;</p>\n' +
    '<p>&nbsp;e. the transfer is necessary for the conclusion or performance of an agreement in the interest of the Data Subject between the Controller and another natural person or legal entity;&nbsp;</p>\n' +
    '<p>f. the transfer is necessary for weighty reasons of general interest;&nbsp;</p>\n' +
    '<p>g. the transfer is necessary for the institution, exercise or substantiation of a legal claim;&nbsp;</p>\n' +
    '<p>h. the transfer is necessary for the protection of the vital interests of the Data Subject or of other persons, if the Data Subject is physically or legally incapable of giving his consent;&nbsp;</p>\n' +
    '<p>i. the transfer was made from a register set up by law that is intended to inform the public.&nbsp;</p>\n' +
    '<p>\n' +
    '  <span style="color:#0d0d0d;">TimeBox website.</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <strong>Principles of Processing Personal Data</strong>\n' +
    '</p>\n' +
    '<p>1. Personal Data are processed in accordance with the law and in a proper and careful manner.&nbsp;</p>\n' +
    '<p>2. Personal Data shall only be processed to the extent that they are adequate, relevant and not excessive in view of the purposes set out in the appendices.&nbsp;</p>\n' +
    '<p>3. Special Personal Data will be processed with due observance of the provisions of Article 9 of the GDPR.&nbsp;</p>\n' +
    '<h4>RIGHTS OF DATA SUBJECTS</h4>\n' +
    '<p>You have the following rights with regard to the processing of your personal data by TimeBox:&nbsp;</p>\n' +
    '<ul>\n' +
    '  <li>Right to access: you have the right to inspect the personal data we process concerning you.</li>\n' +
    '  <li>Right to rectification: you have the right to have the personal data we process concerning you corrected or supplemented if the personal data is incorrect or incomplete.</li>\n' +
    '  <li>Right to withdraw your consent: you may simply withdraw your consent at any time.</li>\n' +
    '  <li>Right to object: you may object to the processing of your personal data.</li>\n' +
    '  <li>Right to erasure: you may request us to erase your personal data.</li>\n' +
    '  <li>Right to data portability: if it is technically possible, you have the right to have the personal data we process concerning you transferred to a third party.</li>\n' +
    '  <li>Right to restriction of processing: in some cases, you can request to restrict the processing of your personal data, whether temporarily or otherwise, which means that we will process fewer data concerning you.</li>\n' +
    '</ul>\n' +
    '<p>To prevent that we provide data to an incorrect person, we may ask for additional information to verify your identity.&nbsp;</p>\n' +
    '<p>We will assess your request in accordance with the General Data Protection Regulation (GDPR). In principle, we will inform you whether we can comply with your request within one month after receiving that request. This term may be extended by two months in specific cases, for example in the event of a complex request. We will inform you about such an extension within one month after having received your request.</p>\n' +
    '<p>\n' +
    '  <span style="color:#0d0d0d;">\n' +
    '    <strong>Retention periods</strong>\n' +
    '  </span>\n' +
    '</p>\n' +
    '<p>1. Personal data will be kept for as long as necessary to achieve the purposes stated in the appendices. Personal data that are no longer necessary for the purpose will be deleted as soon as possible.&nbsp;</p>\n' +
    '<p>2. After termination of the employment or carrying out activities for the benefit of the Controller, the data will be retained for maximum three years, unless these data must be retained for a longer period in connection with legal obligations.</p>\n' +
    '<p>&nbsp;3. Removal implies destruction or such an operation by which it is no longer possible to identify the person.&nbsp;</p>\n' +
    '<p>\n' +
    '  <strong>How to contact us</strong>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:#252524;">If you have any questions about the privacy and cookie policy of Strypes, exercising your rights, or requests regarding transparency, please contact Strypes via email at\n' +
    '  </span>\n' +
    '  <a target="_blank" href="mailto:privacy@strypes.eu">privacy@strypes.eu</a>\n' +
    '  <span style="color:#222222;"></span>\n' +
    '  <span style="color:#252524;">. It is also possible to send your request by post or phone using the above information.</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:#0d0d0d;">\n' +
    '    <strong>Policy amendment</strong>\n' +
    '  </span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:#0d0d0d;">Strypes may amend this privacy statement from time to time. You can always view the most recent version via the hyperlink “/ Privacy Statement”</span>\n' +
    '  <span style="color:#0d0d0d;">\n' +
    '    on the TimeBox website</span>\n' +
    '  <span style="color:#0d0d0d;">.</span>\n' +
    '</p>\n' +
    '<p>\n' +
    '  <span style="color:#0d0d0d;">Date, Version</span>\n' +
    '</p>'
export function PrivacyPolicyComponent (){
    return <TextPageComponent title={"Privacy Policy"}>
        <div className={"px-5"} dangerouslySetInnerHTML={{__html: policyString}}></div>
    </TextPageComponent>
}