import React, {useState} from "react";

export default function InfoNotificationComponent (){
    const [closed, setClosed] = useState<boolean>(false);

    return ( <>
        { (!closed) && (
        <div id="nojira" className="alert alert-warning alert-dismissible show" role="alert">
    <strong>You have logged in using Google/Microsoft SSO! </strong> You will not be able to see any Jira Boards.
    If you want to see your jira boards, logout and login using jira account.
    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={event => setClosed(true)}>
        <span aria-hidden="true">&times;</span>
    </button>
</div>)
        }
    </>
        )
}