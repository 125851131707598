import EmojiAtlassianIcon from '@atlaskit/icon/glyph/emoji/atlassian'
import GsuiteIcon from '@atlaskit/icon/glyph/gsuite'
import PremiumIcon from '@atlaskit/icon/glyph/premium'
import React, {useEffect, useState} from "react";
import {api} from "../api/userAPI";
import {
    Button,
    FormControl,
    FormLabel,
    Input,
    useDisclosure,
    VStack,
    Text,
    AbsoluteCenter,
    Box,
    Divider, Alert, AlertIcon
} from "@chakra-ui/react";
import {ChakraModalFromForm} from "../common_components/ModalFromForm";
import {useNavigate} from "react-router-dom";

export const BACKEND_URL = "https://time-box.eu/api" //"http://localhost:5000"
const USE_MICROSOFT = true; //Make true if Cloud Jira needs to be used
export default function LoginComponent() {
    const {isOpen, onOpen, onClose} = useDisclosure()
    const [noJira, setNoJira] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>("")
    const [formData, setFormData] = useState<Record<string, string   | number | undefined>>({
    username: "", password: ""});
    const navigate = useNavigate()
    const handleSubmit = (e: any) => {
        e.preventDefault()
        api.login(formData).then(value =>{
            // Store user to local storage
            setErrorMessage("")
            localStorage.setItem("user", JSON.stringify(value));
            // Redirect to home page
            navigate("/")
        }
        ).catch(error =>{
            setErrorMessage(error.response.data.message )
            console.log(error.response.data.message)
}
        )
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = e.target as HTMLInputElement;
      setFormData({ ...formData, [name]: value });

  };
    useEffect(() => {
        onOpen()
    }, [onOpen]);
    return (
            <ChakraModalFromForm title={"Login Form"} onClose={onClose} isOpen={isOpen} >
                <VStack>

                    <Text> Log in to Continue</Text>
                    {errorMessage &&
                      <Alert status='error'>
                        <AlertIcon />
                          {errorMessage}
                      </Alert>}
                    {USE_MICROSOFT ?
                        <>
                            <AtlassianButton/>
                            <AzureButton/>
                        </>
                        :
                        <>
                            <OnPremJiraComponent handleSubmit={handleSubmit} handleChange={handleChange} username={formData["username"]} password={formData["password"]}/>
                            <Text>Or continue with: </Text>
                            <GoogleLoginButton/>
                        </>
                    }

                </VStack>
            </ChakraModalFromForm>

    )

}

interface OnPremJiraProps{
    handleSubmit: (e: any) => void
    handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void
    username: string   | number | undefined
    password: string   | number | undefined
}

function OnPremJiraComponent(props: OnPremJiraProps)
{
    return (
    <>
        <form className={"w-100"} onSubmit={props.handleSubmit}>
            <VStack>
                <FormControl isRequired>
                    <FormLabel>Jira Username</FormLabel>
                    <Input placeholder='Username' onChange={props.handleChange} value={props.username} autoComplete={"username"}
                           name={"username"}/>
                </FormControl>

                <FormControl mt={4} isRequired>
                    <FormLabel>Jira Password</FormLabel>
                    <Input placeholder='Enter Password' type={"password"} value={props.password} autoComplete={"current-password"}
                           name={"password"}
                           onChange={props.handleChange}/>
                </FormControl>
                <Button colorScheme='blue' mr={3} type={"submit"}>
                    Sign In
                </Button>
            </VStack>
        </form>
        <Divider/>
    </>
    )
}


function AtlassianButton() {

    return (

        // <button type="button" tabIndex={1} className="btn btn-secondary-outlined   aui-button">
        <Button  colorScheme='blue' className={"btn aui-button"} leftIcon={<EmojiAtlassianIcon label={"atlassian"} />}
         onClick={event => window.location.assign(`${BACKEND_URL}/atlassian/login`)}>
        Log in with Atlassian JIRA
        </Button>
    )
}

function AzureButton() {
    const [dontHaveJira, setDontHaveJira] = useState<boolean>(true)
    return (
        <div className="text-justify text-left">
            <Box position='relative' padding='0'>
            <Divider />
            <AbsoluteCenter bg='white' px='4'>
                 OR
            </AbsoluteCenter>
            </Box>
            {dontHaveJira ?
            <Button  className="btn  btn-secondary-outlined"
                     onClick={event => setDontHaveJira(false)}> I don't have Jira account</Button> :
        // <button type="button" tabIndex={1} className="btn btn-secondary-outlined   aui-button">
            <Button  colorScheme='blue' className={"btn aui-button ml-0"} leftIcon={<PremiumIcon label={"azure"} />}
             onClick={event => window.location.assign(`${BACKEND_URL}/azure/login`)}>
        Log in with ICT account
        </Button>
        }
        </div>
    )
}

function GoogleLoginButton() {
    const [dontHaveJira, setDontHaveJira] = useState<boolean>(true)
    return (
        <div className="text-justify text-left">
            {dontHaveJira?
                <Button  className="btn  btn-secondary-outlined"
                         onClick={event => setDontHaveJira(false)}> I don't have Jira account</Button> :
                <Button  className="btn  btn-secondary-outlined   " leftIcon={<GsuiteIcon label={"Google"} size={"small"}/>}
                onClick={event => window.location.assign(`${BACKEND_URL}/google/login`)}>

            Log in with Google
        </Button>
            }
       </div>
    );
}

