import React, {useState} from "react";
import {BookingEntry} from "../types";
import {Text, Progress, useDisclosure} from "@chakra-ui/react";
import UpdateWorklog from "../../WorklogModals/UpdateWorklog";
import {api} from "../../../api/timesheetAPI";
import {showSwal} from "../../../common_components/Alert";
import moment from "moment";
import {useAppDispatch, useAppSelector} from "../../../stateManagement/hooks";
import {setTimeTableLoading, timetableRefresh} from "../../../stateManagement/refreshers";

interface TableBodyProps {
    dates: Date[],
    detailed: boolean,
    bookings: Record<string, BookingEntry>,
    total_weekly_hours: number,
    time_off: Record<string, string>,
    total_daily_hours: number[],
    total_overtime_hours: number,
    expected_hours: number
    JIRA_BASE_URL: string,
}

function getHoursPrecision(hours: number){
    if (!Number.isInteger(hours)){
        if(hours.toString().split('.')[1].length > 1) {
            return 2
        }
    }
    return 1
}

export function TableBody(props: TableBodyProps) {

    const {isOpen, onClose, onOpen} = useDisclosure();
    const [updateModalInitialData, setUpdateModalInitialData] = useState<BookingEntry|null>(null);
    const impersonated = useAppSelector(state => state.impersonation.impersonatedUser)
    const [updateDay, setUpdateDay] = useState<number>();
    const detailed = props.detailed;
    const today = new Date()
    const dispatch = useAppDispatch()
    function getRowClass(week_day: Date, isBookingCell=true, status="") {
        let className = props.detailed ? " " : " expanded "

        if (week_day.getDate() === today.getDate() &&  week_day.getMonth() === today.getMonth() && !isDayOff(week_day))
        {
            className += " today "
        } else if (week_day.getUTCDay() === 6 || week_day.getUTCDay() === 0) {
            className += " non-business-day "
        }
        if(isDayOff(week_day) && isBookingCell){
            className += " day-off "
        }

        if (detailed){
            className += " expanded "
        }
        if (status === "approved") className += " approved "
        else if (status === "not-approved") className += " not-approved "
        else if (status === "overtime") className += " approved overtime "

        return className
    }
    if (!props.bookings || !props.bookings) {
        return <></>
    }
    function isDayOff(day: Date) {
        const res = Object.entries(props.time_off)
            .find(value =>
                moment(value[0]).format("YYYY-MM-DD") === moment(day).format("YYYY-MM-DD"))

        return res !== undefined

    }
	function getApproval(){
        // if (Object.entries(props.bookings)) TODO filter if any is orange
        dispatch(setTimeTableLoading(true))
        api.getApproval({selected_date: moment(props.dates[0]).format("YYYY-MM-DD"), impersonate: impersonated}).then(value => {
            dispatch(timetableRefresh())
                showSwal("get-approval-success-message");
                        dispatch(setTimeTableLoading(false))
            }
        ).catch(reason =>{ showSwal("failed", reason.response.data)
                dispatch(setTimeTableLoading(false));
        })
	}
    function onOpenManual(booking: BookingEntry, idx_day:number){
        if (booking.story_booking_hours[idx_day] >0) {
            setUpdateModalInitialData(booking)
            setUpdateDay(idx_day)
                    onOpen()
        }


    }

    function getTimeOffText(week_day: Date){
        return props.time_off[moment(week_day).format("YYYY-MM-DD")] || "-"
    }
    return (<>
        <UpdateWorklog isOpen={isOpen} onClose={onClose} initialData={updateModalInitialData} idx_day={updateDay}/>
        <tbody>

        {/*<h4>{JSON.stringify(props.bookings)}</h4>*/}
        {Object.entries(props.bookings).map(([story_key, booking]) =>

            <tr key={"row" + story_key}>
                {/*<h4>{JSON.stringify(booking)}</h4>*/}
                <td className="key" key={"urls"}>
                    <a href={props.JIRA_BASE_URL + "/browse/" + story_key} title={booking.story_name}
                       target="_blank" rel="noreferrer">
                        {story_key}</a>
                    {booking.admin_id !== "Deprecated" &&
                        <div>/<a href={"https://admin.strypes.eu/task/" + booking.admin_id} target="_blank"
                                 rel="noreferrer">{booking.admin_id}</a>
                        </div>
                    }

                </td>
                <td className="summary" key={"summary"}>
                    <a href={props.JIRA_BASE_URL + "/browse/" + story_key} title={booking.story_name}
                       target="_blank" rel="noreferrer">
                        {booking.story_name}
                    </a>
                </td>
                {props.dates.map((week_day, idx_day) => {
                    return (
                        isDayOff(week_day) ?
                            <td
                                key={booking.db_booking_ids[idx_day]+ String(idx_day)}
                                onClick={event => onOpenManual(booking, idx_day)}
                                className={getRowClass(week_day, true, booking.status[idx_day])}>
                                {getTimeOffText(week_day)}
                            </td>
                            :
                            <td
                                key={booking.db_booking_ids[idx_day] + String(idx_day)}
                                onClick={event => onOpenManual(booking, idx_day)}
                                className={getRowClass(week_day, true, booking.status[idx_day])}>
                                {booking.story_booking_hours[idx_day] === 0 ? "-" : booking.story_booking_hours[idx_day].toFixed(getHoursPrecision(booking.story_booking_hours[idx_day]))}
                            </td>
                    )
                })}
            </tr>
        )}
        {Object.entries(props.bookings).length === 0 &&

        <NoBookingsRowComponent dates={props.dates} getRowClass={getRowClass} getTimeOffText={getTimeOffText}/>
        }


        <tr className="total-daily-hours" key={"totalDaily"}>
            <td colSpan={2} key={"totalDaily"}>
                Daily hours total
            </td>
            {props.dates.map((week_day, idx_day) => (
                (
                    <td className={getRowClass(week_day,  false)} key={"totalDaily" + idx_day}>
                        {props.total_daily_hours[idx_day].toFixed(getHoursPrecision(props.total_daily_hours[idx_day]))}
                    </td>
                )
            ))}
        </tr>

        <TotalWeeklyRowComponent totalWeeklyHours={props.total_weekly_hours} totalOvertime={props.total_overtime_hours} expectedHours={props.expected_hours}/>
        {props.detailed &&
            <ApproveButtonRowComponent onClick={event => getApproval()}/>
        }
        </tbody>
    </>)

                    }



function NoBookingsRowComponent(props:{dates: Date[],getRowClass: any , getTimeOffText: any}) {
return (
    <tr key={"zeroRow"}>
        <td className="key no-right-border" key={"tab1"}>
            &nbsp;
        </td>
        <td className="summary no-left-border" key={"summary1"}>
            &nbsp;
        </td>
        {props.dates.map((week_day, idx_day) =>
                    <td className={props.getRowClass(week_day, true)}
                        key={"timeoff" + idx_day}>
                        {props.getTimeOffText(week_day)}
                    </td>
)
        }
    </tr>
)
}

function TotalWeeklyRowComponent(props: { totalWeeklyHours: number, expectedHours: number, totalOvertime:number }) {
    const text = " with " + String(props.totalOvertime) +"h PI";
    return <tr className="total-weekly-hours" key={"totalWeekly"}>
        <td colSpan={2}>
            Weekly hours total
        </td>

        <td colSpan={7} className={"text-blue"}>
            <Progress min={0}
                      value={props.expectedHours === 0 ? 100 : (props.totalWeeklyHours / props.expectedHours) * 100}
                      colorScheme={props.totalWeeklyHours >= props.expectedHours ? "green" : "pink"}/>
            {props.totalWeeklyHours.toFixed(getHoursPrecision(props.totalWeeklyHours))} / {props.expectedHours.toFixed(1)}
            {props.totalOvertime > 0 ?
                <>
                  <Text as={"i"} color={""}>
                      {text}
                  </Text>
                </>
                : <></> }
        </td>
        {/*<hr/>*/}

    </tr>;
}

function ApproveButtonRowComponent(props: { onClick: (event: any) => void }) {
    return <tr className="approve-hours" key={"approvals"}>
        <td colSpan={2}>
            &nbsp;
        </td>
        <td colSpan={7}>
            <button id="approve-hours-btn" className="aui-button aui-style-default"
                    onClick={props.onClick}>
                                    <span>
                                        Send To ERP system
                                    </span>
            </button>

        </td>
    </tr>;
}
