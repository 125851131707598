import axiosInstance, {headers} from './axiosInstance';
import {
  LIST_BOARDS_SERVICE_URL,
  GET_SPRINTS_SERVICE_URL, GET_ISSUES_SERVICE_URL

} from './config';

export const api = {
  // async getAll(requestModel) {
  //   return axiosInstance.get(GET_ALL_EMPLOYEES_URL, {
  //     params: {
  //       pageNumber:
  //         Object.keys(requestModel).length > 0 ? requestModel.pageNumber : null,
  //       pageSize:
  //         Object.keys(requestModel).length > 0 ? requestModel.pageSize : null,
  //       departmentId:
  //         Object.keys(requestModel).length > 0
  //           ? requestModel.departmentId
  //           : null,
  //     },
  //   });
  // },
  // async getOne(userId) {
  //   return axiosInstance.get(`${GET_SINGLE_EMPLOYEE_URL}/${userId}`);
  // },
  // async addEmployeeToMyTeam(model) {
  //   return axiosInstance.put(`${ADD_EMPLOYEE_TO_MY_TEAM_URL}${model.id}`);
  // },
  // async removeEmployeeFromMyTeam(employeeId) {
  //   return axiosInstance.put(
  //     `${REMOVE_EMPLOYEE_FROM_MY_TEAM_URL}${employeeId}`,
  //   );
  // },
  // async updateProfilePhoto(rawImage) {
  //   return axiosInstance.post(UPDATE_IMAGE_URL, rawImage);
  // },
  async getBoards() {
    return axiosInstance.get(`${LIST_BOARDS_SERVICE_URL}`, {headers: headers});
  },
    async getSprints(board_id) {
    return axiosInstance.get(`${GET_SPRINTS_SERVICE_URL}?board_id=${board_id}`, {headers: headers});
  },
    async getIssues(board_id, sprint_id, assignees) {
    // URL: "/ajax_get_issues/board_id/(?P<board_id>[^\/]+)/sprint_id/(?P<sprint_id>[^\/]+)"
    // r"/assignees/(?P<assignees>[^\/]*)",
    return axiosInstance.get(`${GET_ISSUES_SERVICE_URL}board_id/${board_id}/sprint_id/${sprint_id}/assignees/${assignees}`, {headers: headers});
  },
};
