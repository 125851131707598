import React from "react";
import './nav_panel.css'
import logo from './logo.png'
import {useAppDispatch, useAppSelector} from "../../stateManagement/hooks";
import {FavouritesDropdownComponent} from "../../common_components/FavouritesDropdownComponent";
import {Link, useNavigate} from "react-router-dom";
import {api} from "../../api/userAPI";
import {logout} from "../../stateManagement/userSlice";

export default function NavPanel() {
    return (<nav className="navbar navbar-default w-100 m-0 nav-height-150">

        <div className="navbar-header  w-75 text-right">
            <div className="text-right nav-logo-width">
                <Link to={"/"}><img className="img-fluid" src={logo} alt="logo"/></Link></div>
        </div>
        <UserNavigation/>
    </nav>)
}


function UserNavigation() {
    const currentUser = useAppSelector(state => state.user.user)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    function LogMeOut(e: any) {
        api.logout().then(value => {
                dispatch(logout())
                navigate("/login")
            }
        )
    }
    function goToPreferences(e: any){
        navigate("/preferences")
    }
    function goHome(e: any){
        navigate("/")
    }

    return (
        <div className="w-25 h-50 custom-nav">
            <ul className="nav navbar-nav flex-row mr-auto  ">
                {currentUser.user_can_manage ? (
                    <li id="query-user-parent" className="nav-item">
                        <FavouritesDropdownComponent/>
                    </li>
                ) : <></>}
                {/*<li id='menu-timesheets' className="nav-item">*/}
                {/*    <button type="button" data-url="menu_timesheets"*/}
                {/*        // onClick="load_menu_item($(this).attr('data-url'), $(this).parent().attr('id'));"*/}
                {/*            className=" mr-2 btn btn-outline-secondary nav-input-line-height">*/}
                {/*        <i className="ti-reload text-grey"></i>*/}
                {/*        Reload*/}
                {/*    </button>*/}
                {/*</li>*/}
                <li className="nav-item dropdown ">

                    <div className="btn btn-light nav-dropdown-style " id="navbarDropdownMenuLink" data-toggle="dropdown"
                         role="button"
                         title={currentUser.name}>
                        <img id="username-avatar" className="aui-avatar-inner nav-avatar-style"
                             src={currentUser.avatar_url}
                             alt={currentUser.name} title={currentUser.name}/>
                        <span className="caret"/>
                    </div>
                    <div className="dropdown-menu nav-dropdown-menu-style" aria-labelledby="navbarDropdownMenuLink"
                    style={{inset: "48px auto auto -50px"}}>
                        <div className="dropdown-item justify-content-start pl-4" onClick={goHome}>


                            <i className="ti-home text-primary mr-2 pl-0"> </i> Home


                        </div>
                        <div className="dropdown-item justify-content-start pl-4" onClick={goToPreferences}>


                            <i className="ti-settings text-primary mr-2 pl-0"> </i> Preferences


                        </div>
                        <div className="dropdown-item justify-content-start pl-4" onClick={LogMeOut}>


                            <i className="ti-power-off text-primary mr-2 pl-0"> </i> Logout


                        </div>

                    </div>

                </li>


            </ul>
        </div>
    )
}