
export const HourCategories = [
    {label: "Hours in BG", value: "BG"},
    {label: "Hours of Strypes Portugal", value: "PT"},
    {label: "Hours of Front Office NL", value: "FO_NL"},
    {label: "Hours of Front office US", value: "FO_USA"},
    {label: "Account Delivery", value: "AD"},
    {label: "Hours of Functional Dev", value: "FD"},
    {label: "Hours of Software Dev", value: "SD"},
    {label: "Hours of Scrum Master", value: "SM"},
    {label: "Hours of Product Owner", value: "PO"},
]


export const LocationToDefaultCategory: Record<string, string> =
    {
        "BG": "BG",
        "PT": "PT",
        "NL": "Account Delivery",
        "USA": "Account Delivery"
    }