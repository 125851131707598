import { Flex, IconButton} from "@chakra-ui/react";
import React, {useState} from "react";
import Select, {OptionType} from "@atlaskit/select";
import {api} from "../api/userAPI";
import {useAppDispatch, useAppSelector} from "../stateManagement/hooks";
import {setImpersonation} from "../stateManagement/impersonation";
import {StarIcon} from "@chakra-ui/icons";
import {addFavouriteClientSide} from "../stateManagement/userSlice";


interface SearchUserApiResponse {

    email: string
    is_favourite: boolean
    username?: string

}

export function FavouritesDropdownComponent() {

  const [selectBoxOptions, setSelectBoxOptions] = useState<OptionType[]>([])
  const dispatch = useAppDispatch()
   const currentFavourites = useAppSelector(state => state.user.currentFavs);
  // const impersonated_user = useAppSelector(state => state.impersonation.impersonatedUser)
  function search(searchTerm: string){
      // Validate no cyrillic
      if (/[а-яА-ЯЁё]/.test(searchTerm))return;
    api.searchUser(searchTerm).then(value =>{
      let resp: SearchUserApiResponse[] = value.data;
      let options: OptionType[]  =resp.map(value1 => { return {label: value1.email, value: value1.email}})
      setSelectBoxOptions(options);
    })
  }
  function selectItem(item: OptionType) {
        // Add favourite but do not persist it
      if (!currentFavourites.find((value)=>item.value === value )){
        dispatch(addFavouriteClientSide(item.value))

          }
          dispatch(setImpersonation(item.value))
  }
  function addFavourite(item:OptionType){
    api.addFavourite(item.value as string)
        .then((res) => {
            dispatch(addFavouriteClientSide(item.value))
            // dispatch(setImpersonation(item.value))
            }
                )
        .catch((err) => {
    //     TODO raise error
    console.log("AXIOS ERROR: ", err);
  });

  }

  function formatOptionLabel(
      data: any,
      formatOptionLabelMeta: any
  ) {
    return (<>
          <Flex>
        <div className="react-select__option w-18rem " aria-disabled="false"
             id="react-select-9-option-0" tabIndex={-1} >{data.label}


        </div>
            {formatOptionLabelMeta.context ==="menu"&&
          <IconButton size="sm" aria-label='Search database' onClick={event =>{ event.stopPropagation();addFavourite(data)}} icon={<StarIcon />}
  colorScheme='blackAlpha' variant='ghost'/>}
            </Flex>
        </>
    )
  }

  return <Select
      inputId="single-select-example"
      className="single-select  select2-hidden-accessible w-18rem  "
      classNamePrefix="react-select"
      options={selectBoxOptions}
      placeholder="Choose a user"
      spacing={"compact"}
      appearance={"default"}
      formatOptionLabel={formatOptionLabel}
      onInputChange={(newValue: any) => {
        if (newValue) {
          search(newValue)
        }

      }}
      onChange={newValue =>{
        if (newValue) selectItem(newValue)

      }}
  />
}
