import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import { enGB } from 'date-fns/locale/en-GB';
registerLocale("english", enGB)

type PossibleDate = Date |null



interface DateRangeFormProps{
  name: string
  onChange: (new_value: [Date, Date]) => void
}
export function DateRange (props: DateRangeFormProps) {

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState<Date|null>(null);
  const onChange = (dates: [Date, PossibleDate]) => {
    let [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (!end){end=start}
    props.onChange([start,end])
  };
  useEffect(() => {
    onChange([startDate,endDate])
  }, []);
  return (
    <DatePicker name={props.name}
    className={"btn btn-outline-secondary"}
                dateFormat="dd/MM/yyyy"
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      // excludeDates={[addDays(new Date(), 1), addDays(new Date(), 5)]}
      selectsRange
      // inline
      locale="english"
                // showWeekNumbers
    />
  );
};