import React, {useEffect, useState} from "react";
import ChakraModalFromConfig, {FormConfig} from "../../common_components/ModalFormFromConfig";
import {api} from "../../api/timesheetAPI";
import {useAppDispatch, useAppSelector} from "../../stateManagement/hooks";
import {jiraBoardrefreshNofilters, setTimeTableLoading, timetableRefresh} from "../../stateManagement/refreshers";
import {showSwal} from "../../common_components/Alert";
import {BookingEntry} from "../TimeTable/types";

interface UpdateWorklogModalProps {
    isOpen: boolean,
    onClose: () => void,
    initialData: BookingEntry | null,
    idx_day?: number
}

interface BookingDetailsResult {
    bookings_data: BookingForDay[]
    booking_permissions: Record<string, boolean>
}

interface BookingForDay {
    jira_id: string
    admin_id: string
    story_name: string
    status: string
    created: string
    overtime: boolean
    booking_id: number
    jira_worklog_id: number
    story_id: number
    date: string
    hours: number
    user_id: number
}

function UpdateWorklog(props: UpdateWorklogModalProps) {

    // const user = useAppSelector(state => state.user.user)
    const dispatch = useAppDispatch()
    const impersonated  = useAppSelector(state => state.impersonation.impersonatedUser)
    // const [bookingData, setBookingData] = useState<BookingDetailsResult>()
    const [formConfig, setFormConfig] = useState<FormConfig[]>([]);
    const [initialValues, setInitialValues] = useState<Record<string, string | number | boolean | [string, string]>>({});
    const [statuses, setStatuses] = useState<string[]>([])

    useEffect(() => {
        if (props.idx_day !== undefined) {
            const ids = props.initialData?.db_booking_ids[props.idx_day] as string;
            api.getBookingDetails(ids).then(value => {

                let data: BookingDetailsResult = value.data;

                let newInitial: Record<string, string | number | boolean | [string, string]> = {
                    story_name: props.initialData?.story_name ? props.initialData?.story_name : "",
                    db_booking_id:  ids
                }
                let newFormConfig: FormConfig[] = [{
                    label: "Story Name",
                    name: "story_name",
                    type: "text",
                    required: true,
                    disabled: true
                }]
                if (data && data.bookings_data) {
                    const formConfiguration: FormConfig[] = data.bookings_data.map((value, index) => {
                        const label = value.overtime ? "Time Spent(hours) - Performance Incentive": "Time Spent(hours)"
                            return {
                                label: label,
                                name: "time_logged_" + String(value.booking_id),
                                type: "number",
                                disabled: !data.booking_permissions[value.booking_id],
                                postText: <div>Booked on <br/>{value.created}</div>,
                                min: 0

                            }
                        }
                    )
                    const newStatuses:string[] = []
                    newFormConfig = newFormConfig.concat(formConfiguration)
                    data.bookings_data.forEach((value, index) => {
                            newInitial["time_logged_" + String(value.booking_id)] = value.hours
                        newStatuses.push(value.status)

                        }
                    )
                     setStatuses(newStatuses)

                }
                setFormConfig(newFormConfig)
                setInitialValues(newInitial)

            }).catch(error =>{
        console.log(error.response.data)
            showSwal("failed", error.response.data)
        })



        }

    }, [props.idx_day, props.initialData]);

    const handleSubmit = (formData: Record<string, string | boolean | number | [string, string]>) => {
        let api_payload: Record<string, string | boolean | number | [string, string]> = {
            ...formData,  impersonate: impersonated
        }
         const booking_ids = (formData["db_booking_id"] as string ).split("+")

        statuses.forEach((value, index) => {api_payload["db_booking_status_" + booking_ids[index]] = value})
        dispatch(setTimeTableLoading(true))
        api.update(api_payload).then(value => {
            dispatch(timetableRefresh())
            dispatch(jiraBoardrefreshNofilters())
        }).catch(error =>
            showSwal("failed", error.response.data))
            dispatch(setTimeTableLoading(false))

    };


    return (<>
            <ChakraModalFromConfig isOpen={props.isOpen} onClose={props.onClose} onSubmit={handleSubmit}
                                   formConfig={formConfig}
                // title={` ${props.initial.summary_full}`}
                                   initialValues={initialValues}
                                   title={"Update Worklog"}
            />
        </>
    );
};

export default UpdateWorklog;

