import React from "react";
import moment from 'moment';

interface TimeTableNavigationProps {
    date: string
    period: string
    setDate: (date: string) => void
    setPeriod: (period: string) => void
}

export default function TimeTableNavigation(props: TimeTableNavigationProps) {
    function getMonday(d: Date) {
        d = new Date(d);
        var day = d.getDay(),
            diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    }

    function getThisWeek(date: string) {
        const monday = getMonday(new Date(date))
        const sunday = new Date(monday)
        sunday.setDate(sunday.getDate() + 6)
        return {monday, sunday}
    }

    const dateFormat = (date: string) => {
        if (props.period === "monthly") {
            return moment(new Date(date)).format("MMMM, YYYY");

        }

        const {monday, sunday} = getThisWeek(date)
        let start_date_week = moment(monday).format('MMM DD');
        let end_date_week = moment(sunday).format('MMM DD, YYYY');
        let week = moment(monday).isoWeek()
        return "wk" + week + " (" + start_date_week + " - " + end_date_week + ")"
    }

    function next() {
        // Get next date
        const now = moment(new Date(props.date));
        const isMonthly = props.period === "monthly"

        if (isMonthly) {
            props.setDate(moment(now).add(1, 'month').format("YYYY-MM-DD"))
        } else {
            props.setDate(now.add(7, 'days').format("YYYY-MM-DD"))
        }
    }

    function prev() {
        // Get Prev date
        const now = moment(new Date(props.date));
        const isMonthly = props.period === "monthly"

        if (isMonthly) {
            props.setDate(moment(now).subtract(1, 'month').startOf("month").format("YYYY-MM-DD"))
        } else {
            props.setDate(now.subtract(7, 'days').format("YYYY-MM-DD"))
        }
        // Set date
    }

    function curr() {
        // Get Prev date
        const now = new Date();
        now.setDate(now.getDate());
        props.setDate(now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate() + "")
        // Set date
    }

    return (<div className="toolbar-slider-wrapper">
        <div className="toolbar-slider">
            <div className="row mt-2 mb-2  ml-2 navigation-width">
                <div className="btn-group btn-group-justified btn-group-toggle" data-toggle="buttons"
                     id="isMonthly">
                    <label className={"btn btn-outline-secondary " + (props.period === "weekly" ? "active" : "")}>
                        <input type="radio" name="options" value="weekly" id="weekly"
                               checked={props.period === "weekly"}
                               onChange={event => props.setPeriod("weekly")}
                        /> Weekly
                    </label>
                    <label className={"btn btn-outline-secondary " + (props.period === "monthly" ? "active" : "")}>
                        <input type="radio" name="options" id="monthly" value="monthly"
                               checked={props.period === "monthly"}
                               onChange={event => props.setPeriod("monthly")}
                        /> Monthly
                    </label>
                </div>
            </div>
            <div className="row">
                <div className="arrow-left" id="get-prev" data-date=""
                     onClick={event => prev()}
                ></div>
                <div className="text-range-date ml-2 mr-2" id="get-current" data-date={props.date}
                     onClick={event => curr()}
                >
                    <span>{dateFormat(props.date)} </span>
                </div>
                <div className="arrow-right" id="get-next" data-date=""
                     onClick={event => next()}
                ></div>
            </div>
        </div>

    </div>)
}
