import React, {useEffect, useState} from "react";
import {api} from "../../../api/boardsApi";
import Select from "@atlaskit/select";
import {useAppSelector} from "../../../stateManagement/hooks";

interface BoardSelectorProps {
    selectedBoard: string | null,
    setSelectedBoard: (value: string | null) => void
}


interface Board {
    value: string,
    label: string
}


interface BoardsResponse {
    boards: { [key: string]: string };
    username: string,
    user_has_jira: boolean
}


export function BoardSelectorComponent(props: BoardSelectorProps) {
    // const boards = [{board_id: 1, board_name: 'Board 1'}, {board_id: 2, board_name: 'Board 2'}]
    const [boards, setBoards] = useState<Board[]>([])
    const default_board= useAppSelector(state => state.user.preferences.defaultBoard)
    const [selectedBoard, setSelectedBoard] = useState<Board|undefined>(default_board.value? default_board : undefined)
    useEffect(() => {
        api.getBoards().then(value => {
            let boardsResponse: BoardsResponse = value.data;
            let data: Board[] = Object.entries(boardsResponse.boards).sort((a, b) => a[1].toLowerCase()< b[1].toLowerCase()? -1: 1).map(value1 => {
                return {value: value1[0], label: value1[1]}
            })
            setBoards(data);
            if(default_board.value){

                setSelectedBoard(default_board);
                props.setSelectedBoard(default_board.value)}
        })
    }, [default_board.value]);
    return (

        <Select
            inputId="single-select-example"
            className="single-select  select2-hidden-accessible  "
            classNamePrefix="react-select"
            options={boards}
            placeholder="Choose a board"
            spacing={"compact"}
            appearance={"default"}
            value={selectedBoard}
            onChange={(newValue: any) => {
                if (newValue) {
                    setSelectedBoard(newValue)
                    props.setSelectedBoard(newValue.value)
                }

            }}
        />
        /*
                <form className="aui">
                <select id="filter-board" className="js-states form-control select2-hidden-accessible w-100">
                    <option></option>
                    {boards.map((board, index) =>
                    <option value={board.board_id}>{board.board_name}</option>

                    )}
                </select>
        */
        // </form>
    )
}

