import axiosInstance, {headers} from './axiosInstance';
import {
  ADD_WORKLOG_BUTTON_SERVICE_URL, ADD_WORKLOG_SERVICE_URL, GET_APPROVAL_SERVICE_URL,
  GET_TIMETABLE_SERVICE_URL, GET_WORKLOG_DETAILS, UPDATE_WORKLOG_SERVICE_URL


} from './config';

export const api = {
  // async getAll(requestModel) {
  //   return axiosInstance.get(GET_ALL_EMPLOYEES_URL, {
  //     params: {
  //       pageNumber:
  //         Object.keys(requestModel).length > 0 ? requestModel.pageNumber : null,
  //       pageSize:
  //         Object.keys(requestModel).length > 0 ? requestModel.pageSize : null,
  //       departmentId:
  //         Object.keys(requestModel).length > 0
  //           ? requestModel.departmentId
  //           : null,
  //     },
  //   });
  // },
  // async getOne(userId) {
  //   return axiosInstance.get(`${GET_SINGLE_EMPLOYEE_URL}/${userId}`);
  // },
  // async addEmployeeToMyTeam(model) {
  //   return axiosInstance.put(`${ADD_EMPLOYEE_TO_MY_TEAM_URL}${model.id}`);
  // },
  // async removeEmployeeFromMyTeam(employeeId) {
  //   return axiosInstance.put(
  //     `${REMOVE_EMPLOYEE_FROM_MY_TEAM_URL}${employeeId}`,
  //   );
  // },
  // async updateProfilePhoto(rawImage) {
  //   return axiosInstance.post(UPDATE_IMAGE_URL, rawImage);
  // },
  async getTimeTable( date="",  period="weekly", impersonate="") {
    return axiosInstance.get(`${GET_TIMETABLE_SERVICE_URL}date/${date}/period/${period}/impersonate/${impersonate}`, {headers: headers});
  },

  async addFromJirabutton( formData: any) {
    return axiosInstance.post(`${ADD_WORKLOG_BUTTON_SERVICE_URL}`, formData,{headers: headers});
  },

    async addFromJiraCards( formData: any) {
    return axiosInstance.post(`${ADD_WORKLOG_SERVICE_URL}`, formData, {headers: headers});
  },
      async getApproval( formData: any) {
    return axiosInstance.post(`${GET_APPROVAL_SERVICE_URL}`, formData, {headers: headers});
  },
    async update( formData: any) {

    return axiosInstance.patch(`${UPDATE_WORKLOG_SERVICE_URL}`,formData, {headers: headers});
  },
  async getBookingDetails(db_booking_ids: string){
    return axiosInstance.get(`${GET_WORKLOG_DETAILS}`+db_booking_ids, {headers:headers})
  }
};
