import React from "react";
import './issue-card.css'
import {Backlog} from "../types";
import  ico_story from './ico_story.png'
import {MenuItem, MenuList, useDisclosure} from "@chakra-ui/react";
import AddWorklogUsingJiraCards from "../../../WorklogModals/AddWorklogUsingJiraCards";
import { ContextMenu } from 'chakra-ui-contextmenu';



//
interface CardProps {
    issue:Backlog,
    key: string
}
export function IssueCardComponent(props:CardProps) {
    const issue = props.issue
    const {isOpen, onClose, onOpen} = useDisclosure();
    const JIRA_BASE_URL = "https://ictgroupeu.atlassian.net"
    // const JIRA_BASE_URL  = "https://cmjira.cm.ict.nl/jira"
    const jira_issue_url = JIRA_BASE_URL + "/browse/" + issue.key
    const menuRender = () => {return (
              <MenuList>
        <MenuItem onClick={event =>
            window.open(jira_issue_url, '_blank')?.focus() }><i>{"Go To "  + issue.key + ' (new window)'}</i></MenuItem>
      </MenuList>)
    }
    // const issue: Issue = {key: "TSAT-204", summary: "Test story doesnt exist in admin but can be..", avatarUrl: "", avatarName: "", originalEstimate: {seconds: 0, size: 0, percentage: 0, value: ""},
    //     remainingEstimate: {seconds: 0, size: 2, percentage: 50, value: ""},
    //     timeSpent: {seconds: 0, size: 1, percentage: 75, value: ""},
    // summary_full: "Test story doesnt exist in admin but can be booked on"};

    return (<div className="content-fluid issue-card" id={"issue-key-card--" + issue.key} key={"issue-key-card--" + issue.key}>
        <div onClick={event =>onOpen()}>
            <AddWorklogUsingJiraCards isOpen={isOpen} onClose={onClose} initial={props.issue}/>
            <ContextMenu<HTMLDivElement>     renderMenu={menuRender}>
 {ref =>
            <div className="row col-md-offset-0 mt-1 ml-1 mr-1 issue-card-container" ref={ref}>

                <div className=" col-12 padding-0">

                    <div className="row ml-1">

                        <div className="col-8 col-sm-9 col-md-9 padding-0">
                            <div className="row ico-story-image" >
                                <div className=" mr-3">
                                    <img src={ico_story} alt={"story icon"}/>
                                </div>
                                <div
                                    className="issue-key"
                                    title={issue.key}>
                                    <strong>{issue.key}</strong>
                                </div>


                            </div>
                            <div className="row item-summary ml-3  text-dark"
                                 title={issue.summary_full}>
                                {issue.summary}
                            </div>
                            {/*<script>*/}
                            {/*    AJS.$("#issue-key-card--{{issue['key']}} .item-summary").tooltip();*/}
                            {/*</script>*/}
                        </div>

                        <div className="col-3 col-sm-2 col-md-2 avatar-col">
                            <div className="row text-right padding-10 mr-3 avatar-col-container" >
                                {issue.avatarUrl &&
                                <img src={issue.avatarUrl}  alt={"user avatar"}
                                     title={issue.avatarName}
                                     className="avatar_pic"/>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="container p-0">
                        <div className="row  ml-2">
                            <div className="col-10 col-sm-10 col-md-10 padding-0"
                                 id={"issue-key" + issue.key}>

                                <div className="row ml-2">
                                    <div className="col-8 col-sm-9 padding-0">
                                        <div id="original-estimate-graph"
                                             className="progress original-estimate-graph"
                                             style = {{width: issue.originalEstimate.size + "%"}}
                                        >
                                            <div className=" progress-bar bg-info original-esimate-progress-bar"
                                                 style = {{width: issue.originalEstimate.percentage + "%"}}
                                            >
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-1 col-sm-1 col-md-2 padding-0">
														<span className="original-estimate-text"
                                                        >
                                                            {issue.originalEstimate.value}
														</span>
                                    </div>
                                </div>

                                <div className="row ml-2">
                                    <div className="col-8 col-sm-9 padding-0">
                                        <div id="remaining-estimate-graph"
                                             className="progress remaining-estimate-graph"
                                             style = {{width: issue.remainingEstimate.size + "%"}}
                                             // style="width:
                                             //                             {{ issue['remainingEstimate']['size'] }}%;"
                                        >
                                            <div
                                                className="progress-bar progress-bar-rem remaining-estimate-progress-bar"
                                                style = {{width: 100 - issue.remainingEstimate.percentage + "%"}}
                                            >
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-1 col-sm-1 col-md-2 padding-0">
														<span className="remaining-estimate-text"
                                                            >
															{issue.remainingEstimate.value}
														</span>
                                    </div>
                                </div>

                                <div className="row ml-2">
                                    <div className="col-8 col-sm-9 padding-0">
                                        <div id="time-spent-graph" className="progress spent-time-graph"
style = {{width: issue.timeSpent.size + "%"}}
                                        >
                                            <div className="progress-bar bg-success spent-time-progress-bar"
style = {{width: issue.timeSpent.percentage + "%"}}
                                            >
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-1 col-sm-1 col-md-2 padding-0">
														<span className="spent-time-text"

                                                        >
														{issue.timeSpent.value}

														</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            </ContextMenu>
        </div>
    </div>)
}
