import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
} from '@chakra-ui/react'
import React, {PropsWithChildren} from "react";


interface ModalProps{
  title: string
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  closeButton?: boolean;
}
export const ChakraModalFromForm: React.FC<PropsWithChildren<ModalProps>> =
    ({ title,closeButton, isOpen, onClose, onSubmit, children }) => {

  const initialRef = React.useRef(null)

    const OverlayTwo = () => (
    <ModalOverlay
       bg="rgba(0, 0, 0, 0.5)"
      backdropFilter='auto'
       zIndex={800}
      // backdropInvert='80%'
      // backdropBlur='1px'
    />
  )

  return (
    <>


      <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        isCentered={true}

      >
          {OverlayTwo()}
        <ModalContent containerProps={ {zIndex: '1000'}}>
          <ModalHeader>{title}</ModalHeader>
            {Boolean(closeButton) && <ModalCloseButton />}
          <ModalBody pb={6}>
              {children}
          </ModalBody>

          <ModalFooter>
              {Boolean(closeButton) && <Button onClick={onClose}>Cancel</Button>}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
};