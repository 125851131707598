import Swal from 'sweetalert2'
type swalType = "basic"| "title-and-text"| "success-message"| "validation"| "failed" | "get-approval-success-message"|"no-connection"
export function showSwal (popUptype:swalType, text="") {
    if (popUptype === 'basic') {
      Swal.fire({
        text: 'Any fool can use a computer',
        confirmButtonText: "OK"
      })

    } else if (popUptype === 'title-and-text') {
      Swal.fire({
        title: 'Read the alert!',
        text: 'Click OK to close this alert',
        confirmButtonText: "OK"
      })

    } else if (popUptype === 'get-approval-success-message') {
      Swal.fire({
        title: 'Congratulations!',
        text: 'Your hours have been reflected in the Strypes ERP systems.',
        icon: 'success',
        confirmButtonText: "Continue",

        timer: 3000,
        timerProgressBar: true,
      })

    }
    else if (popUptype === 'success-message') {
      Swal.fire({
        title: 'Congratulations!',
        text: 'Your preferences have been reflected in the system.',
        icon: 'success',
        confirmButtonText: "Continue",

        timer: 3000,
        timerProgressBar: true,
      })}
    else if (popUptype === 'validation') {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!\n' + text,
        footer: '<a href="https://docs.google.com/document/d/1LzRWPQ6exGobwk-M-u0YJNEsty6dVytuYvXHfKizPx8">Why do I have this issue?</a>'
      })
    } else if (popUptype === 'failed') {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!\n' + text,
        footer: '<a href="https://docs.google.com/document/d/1LzRWPQ6exGobwk-M-u0YJNEsty6dVytuYvXHfKizPx8">Why do I have this issue?</a>'
      })

    }
    else if(popUptype === "no-connection"){
            Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Connection Refused. Please check your internet and try again later',
        footer: '<a href="https://docs.google.com/document/d/1LzRWPQ6exGobwk-M-u0YJNEsty6dVytuYvXHfKizPx8">Why do I have this issue?</a>'
      })
    }
  }
