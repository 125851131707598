
import React from "react";
import { Card, CardBody, CardFooter, CardHeader, Heading} from "@chakra-ui/react";
interface MyProps {
    title: string
}

export default function TextPageComponent(props: React.PropsWithChildren<MyProps>) {

    return (
<>

  <div className={"container d-flex align-items-center justify-content-center mt-3 mb-3"} style={{minHeight: "50vh" }}>
<Card align='center' className={""} style={{minHeight: "40vh" }}>
  <CardHeader>
    <Heading size='md'>{props.title}</Heading>
  </CardHeader>
  <CardBody>
      {props.children}
  </CardBody>
  <CardFooter>
  </CardFooter>
</Card>
</div>
</>
    )
}
