import React, {useEffect, useState} from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
  Select,
  ModalFooter, HStack
} from "@chakra-ui/react";
import {DateRange} from "./DateComponents";
import moment from "moment";


interface ModalProps {
  title: string
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (formData: Record<string, string | boolean | number | [string, string]>) => void;
  formConfig: FormConfig[]; // This will be the configuration for form fields,
 initialValues: Record<string, string | boolean | number | [string, string]>;
}

type SupportedTypes = 'text' | 'number' | 'boolean' | 'dateRange' | 'dropdown';
export interface FormConfig {
  label: string;
  name: string;
  type: SupportedTypes,
  required?: boolean,
 options?: { label: string; value: string }[];
  postText?: React.ReactNode,
  disabled?: boolean
  min?: number
  secured?: boolean
}

const ChakraModalFromConfig: React.FC<ModalProps> = ({ title, isOpen, onClose, onSubmit, formConfig , initialValues}) => {
  const [formData, setFormData] = useState<Record<string, string | boolean | number | [string, string]>>(initialValues);
  const [secured, setSecured] = useState<Record<string, boolean>>({})
  useEffect(() => {
    setFormData(initialValues); // Update form data when initialValues change
  }, [initialValues]);



  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target as HTMLInputElement;
    if (type === 'checkbox') {
        let checked_val = (e.target as HTMLInputElement).checked
      setFormData({ ...formData, [name]: checked_val });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleNumberChange = (valueAsString: string, valueAsNumber: number, name: string) => {

    setFormData({ ...formData, [name]: valueAsString });
  };
  const handleDropdownChange = (value: string, name: string) => { // Added dropdown change handler
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = () => {
    const toSubmit = {...formData}
    formConfig.forEach(field => {
      if (field.secured && !getSecurityVal(field.name)) {
        delete toSubmit[field.name]
      }
    })
    onSubmit(toSubmit);
    onClose();
  };
  const handleDateChange = (field_name: string, dates: [Date, Date])=>{
    const date_strings: [string, string] = [moment(dates[0]).format("YYYY-MM-DD"), moment(dates[1]).format("YYYY-MM-DD")]
  setFormData({ ...formData, [field_name]: date_strings });
  }
  const handleSecurityChange = (field_name: string)=>{
    const curVal =getSecurityVal(field_name)
    setSecured({...secured, [field_name + "_security"]: !curVal})
  }
  const getSecurityVal = (field_name: string)=>{
    if (secured[field_name + "_security"] === undefined ||  !secured[field_name + "_security"]) return false;

    return secured[field_name + "_security"]
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {formConfig.map((field, index) => (
            <FormControl key={index} mb={4}>
              <FormLabel>{field.label} {field.required && <Text as={"i"} color="red">*</Text>}</FormLabel>



              {field.type === 'text' && (
                <Input type="text" name={field.name} onChange={handleChange} value={formData[field.name] as string} disabled={Boolean(field.disabled)}/>
              )}
              {field.type === 'number' && (
                  <HStack>
                <NumberInput precision={2} isDisabled={field.disabled ===true || (field.secured && !getSecurityVal(field.name))} min={field.min !== undefined? field.min : 0.1}  name={field.name} onChange={(valueAsString, valueAsNumber) => handleNumberChange(valueAsString, valueAsNumber, field.name)}
                value={formData[field.name] as string}
                            inputMode={'decimal'} >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                    {field.postText &&  <FormLabel>{field.postText}</FormLabel>}
                    {field.secured!== undefined &&(
                        <><Checkbox name={field.name + "_security"} onChange={event => handleSecurityChange(field.name)}  isChecked={getSecurityVal(field.name)} size='lg' colorScheme='orange'/>
                                    <FormLabel>edit </FormLabel></>
                    ) }
                    </HStack>
              )}
              {field.type === 'boolean' && (


                <Checkbox name={field.name} onChange={handleChange}  isChecked={formData[field.name] as boolean} size='lg' colorScheme='orange'/>
              )}
              {field.type === 'dateRange' && (<>
            <DateRange name={field.name} onChange={new_value => handleDateChange(field.name, new_value)}  />
                  </>
              )}
              {field.type === 'dropdown' && ( // Added dropdown rendering
                <Select name={field.name} onChange={(e) => handleDropdownChange(e.target.value, field.name)} value={formData[field.name] as string} >
                  {field.options?.map(option => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                  ))}
                </Select>
              )}
            </FormControl>
          ))}
        </ModalBody>
        <ModalFooter>
        <Button colorScheme="blue" onClick={handleSubmit} mr={3}>OK</Button>
        <Button variant="ghost" onClick={onClose}>Cancel</Button>
          </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ChakraModalFromConfig;