export const LIST_BOARDS_SERVICE_URL = `boards`;
export const GET_SPRINTS_SERVICE_URL = `sprints`;
export const GET_ISSUES_SERVICE_URL = `jira_issues/`;
export const GET_TIMETABLE_SERVICE_URL = "timetable/";
export const GET_APPROVAL_SERVICE_URL = "timetable/approval";
export const ADD_WORKLOG_SERVICE_URL = "worklog/save";
export const ADD_WORKLOG_BUTTON_SERVICE_URL = "worklog/save/nojira";
export const UPDATE_WORKLOG_SERVICE_URL = "worklog/update";
export const ADD_FAVOURITES_SERVICE_URL = "favourites";
export const SEARCH_USER_URL = "ajax_query_user"
export const GET_WORKLOG_DETAILS = "worklogs/db_booking_ids/"
export const PREFERENCES_SERVICE_URL = "/users/me/preferences"
export const CURRENT_USER_URL = "/users/me"
export const LOGIN_URL = "/jira/login"
export const LOGOUT_URL = "/google/logout"
export const TEAMS_URL = "/teams"