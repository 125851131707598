import React, {useEffect} from 'react';
import NavPanel from './NavigationPanel/NavigationPanel';
import JiraContent from "./JiraContent/JiraContent";
import TimeTable from "./TimeTable/TimeTable";
import {Link, Route, Routes, useNavigate} from "react-router-dom";
import LoginComponent from "./LoginComponent";
import {api} from "../api/userAPI";
import {useAppDispatch, useAppSelector} from "../stateManagement/hooks";
import {initialLoadUserPreferences, login, updatePreferences} from "../stateManagement/userSlice";
import PreferencesPage from "./PreferencesComponent";
import {Box, Button, Divider, Flex, HStack, SimpleGrid, VStack} from "@chakra-ui/react";
import strypesLogo from "./Footer/strypes-logo.svg"
import {TermsOfServiceComponent, PrivacyPolicyComponent} from "./TermsOfService";
export default function MainPage() {


    return (<>


        <div>
            <NavPanel/>
            <Routes>
                <Route path={"privacy"} element={<PrivacyPolicyComponent/>}/>
                <Route path={"terms_of_service"} element={<TermsOfServiceComponent/>}/>
                <Route path={"/login"} element={<LoginComponent/>}/>
                <Route path={"/*"} element={<ProtectedPage/>}/>

            </Routes>

            <JiraFooter/>

        </div>


    </>)

}

function ProtectedPage() {
    const user = useAppSelector(state => state.user.user)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        if (!user.email) {
            api.me()
                .then(value =>{
                    dispatch(login(value.data))
                api.getPreferences().then(value =>{dispatch(initialLoadUserPreferences(value.data))})

                } )
                .catch(reason =>
            navigate("/login")
                )

        }
    }, [dispatch, navigate, user.email]);
    return (
        <div id="jira-content" className="old-div bg-white">
{user.email &&
            <div id="">
            <Routes>

                <Route path={"preferences"} element={<PreferencesPage/>}/>

                <Route path={"/"} element={
                    <>
                <TimeTable/>
                        <Divider size={"2px"} style={{margin:"0px"}}/>
                <JiraContent/>
                    </>}/>


            </Routes>
            </div>}
        </div>
    );
}
function JiraFooter() {
    return (
        <div style={{ backgroundColor: "#f8f8f8"}}>
                <HStack spacing='50px' align={"center"} justifyContent={"center"}>
<Box>
            <img src={strypesLogo}/>
</Box>

                <Box>
            <Link to={"terms_of_service"}>
            Terms of Service</Link>
</Box>
                  <Box>
            <Link to={"privacy"}>
            Privacy Policy</Link>
                  </Box>
                    </HStack>
        </div>

    )
}
