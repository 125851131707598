import {createSlice} from "@reduxjs/toolkit";

interface Refresher {
    timetableRefresher: boolean
    jiraBoardrefresher: boolean
    timeTableLoading: boolean
}

const initialState: Refresher= {timetableRefresher: false, jiraBoardrefresher: false, timeTableLoading:false};

export const refresherSlice = createSlice({
  name: 'refresh',
  initialState: initialState,
  reducers: {
    timetableRefresh: (state)=>{
      state.timetableRefresher = !state.timetableRefresher
    },
    jiraBoardrefreshNofilters: (state)=>{
      state.jiraBoardrefresher = !state.jiraBoardrefresher
    },
    setTimeTableLoading:(state, payload)=>{
      state.timeTableLoading =payload.payload
    }
  }
})
// Export the reducer function
export const {jiraBoardrefreshNofilters, timetableRefresh, setTimeTableLoading } = refresherSlice.actions
export default refresherSlice.reducer