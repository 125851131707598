import Select from "@atlaskit/select";
import React from "react";


export interface Sprint {
    id: number
    name: string

}
interface SprintSelectorProps {
    sprints: Sprint[],
    selectedSprint: Sprint | null,
    setSprint: (value: Sprint | null) => void
}

export function SprintSelectorComponent(props: SprintSelectorProps) {

    const defaultSprint = props.selectedSprint ? {
        value: props.selectedSprint.id,
        label: props.selectedSprint.name
    } : undefined

    return (<>
            <Select
                inputId="sprint-select"
                className="single-select  select2-hidden-accessible  w-18rem "
                classNamePrefix="react-select"
                value={defaultSprint}
                options={props.sprints.map(value => {

                    return {value: value.id, label: value.name}
                })}
                placeholder="Choose a sprint"
                spacing={"compact"}
                appearance={"default"}
                onChange={(newValue: any) => {

                    if (newValue) {
                        props.setSprint({id: newValue.value, name: newValue.label})
                    }

                }}
            />
        </>
    )
}

