import React from "react";
import {useDisclosure} from "@chakra-ui/react";
import ChakraModalFromConfig, {FormConfig} from "../../common_components/ModalFormFromConfig";
import {api} from "../../api/timesheetAPI";
import {useAppDispatch, useAppSelector} from "../../stateManagement/hooks";
import {jiraBoardrefreshNofilters, setTimeTableLoading, timetableRefresh} from "../../stateManagement/refreshers";
import {showSwal} from "../../common_components/Alert";
import {HourCategories, LocationToDefaultCategory} from "./hourCategories";
import moment from "moment";

function AddWorklogUsingJira() {
    const {isOpen, onClose, onOpen} = useDisclosure();
    const user = useAppSelector(state => state.user.user)
    const impersonated = useAppSelector(state => state.impersonation.impersonatedUser)
    const dispatch = useAppDispatch()

    const handleSubmit = (formData: Record<string, string | boolean | number | [string, string]>) => {
        dispatch(setTimeTableLoading(true))
        api.addFromJirabutton({...formData, impersonate: impersonated}).then(value => {
            dispatch(timetableRefresh())
            dispatch(jiraBoardrefreshNofilters())
        }).catch(error =>
        showSwal("failed", error.response.data))
        dispatch(setTimeTableLoading(false))

    };

    const formConfig: FormConfig[] = [
        {label: "Story Jira ID", name: "jira_id", type: "text", required: true},
        {
            label: "Hours Category", name: "category", type: "dropdown", required: true,
            options: HourCategories
        },
        {label: "Log Period", name: "dates", type: "dateRange" , required: true},
        {label: "Time Spent per day", name: "time_logged", type: "number", postText: "(hours)" , required: true},
        {label: "Comment", name: "comment", type: "text"},
        {label: "Performance Incentive", name: "overtime", type: "boolean"}
    ];

    const initialValues = {
        jira_id: '',
        category: LocationToDefaultCategory[user.location],
        overtime: false,
        dates: [moment(new Date()).format("YYYY-MM-DD"), ""] as [string, string],

    }

    return (<>
            <button onClick={onOpen} className="aui-button">Book time with story Jira ID</button>
            <ChakraModalFromConfig isOpen={isOpen} onClose={onClose} onSubmit={handleSubmit} formConfig={formConfig}
                                   title={"Book time with story Jira ID"} initialValues={initialValues}/>
        </>
    );
};

export default AddWorklogUsingJira;

