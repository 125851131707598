import React, {useEffect, useState, Suspense} from "react";
import "./timetable.css";
import TimeTableNavigation from "./TimeTableNavigation";
import {api, api as userAPI} from "../../api/userAPI";
import {api as TimeSheetAPI} from "../../api/timesheetAPI";
import {TimeTableAPI} from "./types";
import {WeekTableComponent} from './WeekTable'
import {Spinner, Tab, TabList, Tabs, TabPanels, TabPanel, useBreakpointValue, Center,} from "@chakra-ui/react";
import moment from "moment";
import {useAppDispatch, useAppSelector} from "../../stateManagement/hooks";
import {reset, setImpersonation} from "../../stateManagement/impersonation";
import {CloseButton} from '@chakra-ui/react'
import {removeFavouriteClientSide} from "../../stateManagement/userSlice";
import {setTimeTableLoading} from "../../stateManagement/refreshers";

export default function TimeTable() {
    const today: string = moment(new Date()).format("YYYY-MM-DD")
    //const today: string = "2023-05-05"
    const impersonatedUser = useAppSelector(state => state.impersonation.impersonatedUser)
    const [date, setDate] = useState(today)
    const [selectedTeamMembers, setSelectedTeamMembers] = useState<string[]>([])
    const [period, setPeriod] = useState("weekly")
    const [data, setData] = useState<TimeTableAPI | null>(null)
    const preferences = useAppSelector(state => state.user.preferences)
    const favourites = useAppSelector(state => state.user.currentFavs)
    const userCanManage = useAppSelector(state => state.user.user.user_can_manage)
    const teams: string[] = preferences.favouriteTeams
    const [selectedTeam, setSelectedTeam] = useState<number>(0)
    let refresh = useAppSelector(state => state.refresh.timetableRefresher)

    const dispatch = useAppDispatch()
    useEffect(() => {
        TimeSheetAPI.getTimeTable(date, period, impersonatedUser).then(value => {
                let data: TimeTableAPI = value.data;
                setData(data)
                dispatch(setTimeTableLoading(false))
            }
        )
    }, [date, period, impersonatedUser, refresh]);

    function getTeamMembers(teamName: string) {
        api.getTeam(teamName).then(value => {

                setSelectedTeamMembers(value.data)
            }
        ).catch()
    }

    const handleTabChange = (index: number) => {
        if(userCanManage){
        getTeamMembers(teams[index - 1])
        setSelectedTeam(index)
            dispatch(reset())
}
    };
    return (
        <div id="ajax-time-table-wrapper">

            {data &&
                <><TimeTableNavigation date={date} period={period} setDate={setDate} setPeriod={setPeriod}/>
                    {/*<TimeTableComponent data={data}/>*/}

                    {userCanManage ?
                    <Tabs index={selectedTeam} isLazy variant={"enclosed-colored"} onChange={handleTabChange}
                          colorScheme='green' style={{backgroundColor: "white"}}>

                        <TabList>
                            <Tab key={"Favourites"}>Favourites </Tab>
                            {preferences.showTeams &&  <>
                                {teams.map((team) => (

                                    <Tab key={team}>{team} </Tab>

                                ))}</>}
                        </TabList>

                        {/*<TimeTableComponent data={data}/>*/}

                        <TabPanels>
                            <TabPanel key={"Favourites"}>
                                <Suspense fallback={<Spinner/>}>

                                    <TimeTableComponent data={data} displayUsers={favourites} showRemoveButton={true}
                                    />
                                </Suspense>
                            </TabPanel>
                            {preferences.showTeams  ?
                                teams.map(value =>
                                    <TabPanel key={value}>
                                        <Suspense fallback={<Spinner/>}>

                                            <TimeTableComponent data={data} displayUsers={selectedTeamMembers}
                                                                showRemoveButton={false}/>
                                        </Suspense>
                                    </TabPanel>
                                ) : <></>
                            }

                        </TabPanels>
                    </Tabs>:
                                        <Suspense fallback={<Spinner/>}>

                                            <TimeTableComponent data={data} displayUsers={selectedTeamMembers}
                                                                showRemoveButton={false}/>
                                        </Suspense>}

                </>}
        </div>)
}

interface TimeTableProps {
    data: TimeTableAPI //Only weeks needed?
    displayUsers: string[]
    showRemoveButton: boolean
}

function TimeTableComponent(props: TimeTableProps) {
    const dispatch = useAppDispatch();
    const impersonatedUser = useAppSelector(state => state.impersonation.impersonatedUser)
    const currentUser = useAppSelector(state => state.user.user)
    const loading = useAppSelector(state => state.refresh.timeTableLoading)
    const userCanManage = useAppSelector(state => state.user.user.user_can_manage)
    const displayUsers = [{value: currentUser.email, label: currentUser.name}].concat(
        props.displayUsers.filter((value) => Boolean(value) && value !== currentUser.email).map(value => {
            return {value: value, label: convertEmailToNames(value)}
        }))

    function convertEmailToNames(email: string) {
        // Split the email address using '.' as the separator
        const parts = email.split('@')[0].split(".");

        // Capitalize the first letter of each name
        const firstName = parts[0].charAt(0).toUpperCase() + parts[0].slice(1);
        const lastName = parts[1].charAt(0).toUpperCase() + parts[1].slice(1);

        // Concatenate the names with a space in between
        return `${firstName} ${lastName}`;

    }

    const handleTabChange = (index: number) => {
        if (displayUsers[index].value === currentUser.email) {
            dispatch(reset())
        }
        dispatch(setImpersonation(displayUsers[index].value))

    };
    const tabIndexActual = () => {
        let idx = displayUsers.findIndex(value => value.value === impersonatedUser);
        if (idx === -1) {
            return 0;
        }
        return idx;
    }
    const removeFav = (fav: string) => {
        userAPI.removeFavourite(fav).then(value =>{
            dispatch(removeFavouriteClientSide(fav))
        if (fav===impersonatedUser){
            dispatch(reset())
            }
        }
        )
    }
    // const groupedData = []
    //
    // while (displayUsers.length) {
    //     groupedData.push(displayUsers.splice(0, 8));
    // }
    return (
        <div id="ajax-time-table" className={loading ? "loading" : ""} key={"first"}>
            {/*<h4> {JSON.stringify(props)}</h4>*/}
            {/*<div className="card">*/}
            {/*    <div className="card-header">*/}
            {userCanManage ?
            <Tabs index={tabIndexActual()} onChange={handleTabChange} isLazy variant={"enclosed-colored"}
                  colorScheme='green'>


                <TabList className={"d-flex flex-row flex-wrap"}>
                    {displayUsers.map((displayUser, index) => (
                        <Tab
                            key={displayUser.value}>{displayUser.label} {props.showRemoveButton && currentUser.name !== displayUser.label &&
                            <CloseButton className={"ml-2"} size='sm' colorScheme={"orange"}
                                         onClick={event => removeFav(displayUser.value)}/>}</Tab>
                    ))}
                </TabList>


                <TabPanels>
                    {displayUsers.map(value =>
                        <TabPanel key={value.value} bg={"white"}>
                            <Suspense fallback={<Spinner/>}>
                                {props.data.weeks.map((value, index) => <div className={"mb-4"}
                                                                             key={value.week_dates[0]}>
                                    <WeekTableComponent JIRA_URL={props.data.JIRA_BASE_URL}
                                                        data={value} detailed={props.data.detailed} index={index}/>
                                </div>)}
                            </Suspense>
                        </TabPanel>
                    )}

                </TabPanels>
            </Tabs>
            : <>
                            <Suspense fallback={<Spinner/>}>
                                {props.data.weeks.map((value, index) => <div className={"mb-4"}
                                                                             key={value.week_dates[0]}>
                                    <WeekTableComponent JIRA_URL={props.data.JIRA_BASE_URL}
                                                        data={value} detailed={props.data.detailed} index={index}/>
                                </div>)}
                            </Suspense>
                </>}

        </div>)
}