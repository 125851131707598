import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader, Flex,
    FormControl,
    FormLabel,
    Heading, Switch,
} from "@chakra-ui/react";
import EmojiIcon from '@atlaskit/icon/glyph/emoji';
import Select, {ActionMeta, components, DropdownIndicatorProps, OptionType} from '@atlaskit/select';
import {api as userApi} from "../api/userAPI";
import {useAppDispatch, useAppSelector} from "../stateManagement/hooks";
import {api} from "../api/boardsApi";
import {showSwal} from "../common_components/Alert";
import {updatePreferences} from "../stateManagement/userSlice";
import {Preferences} from "../types";

export default function PreferencesPage() {
   const preferences = useAppSelector(state => state.user.preferences)
   const userCanManage = useAppSelector(state => state.user.user.user_can_manage)
   const [currentUnsavedPreferences, setCurrentUnsavedPreferences] = useState<Preferences>(preferences)
    const dispatch = useAppDispatch()

    useEffect(() => {
        setCurrentUnsavedPreferences(preferences)
    }, [preferences]);
    function savePreferences(){
    userApi.updatePreferences(currentUnsavedPreferences).then(value => {
        showSwal("success-message", "Preferences Saved")
            return dispatch(updatePreferences(currentUnsavedPreferences));
        }
    ).catch(reason =>         showSwal("failed", reason))
    }
    return (
<>
  <div className={"d-flex align-items-center justify-content-center"} style={{minHeight: "50vh" }}>
<Card align='center' className={""} style={{minHeight: "40vh" }}>
  <CardHeader>
    <Heading size='md'> User Preferences</Heading>
  </CardHeader>
  <CardBody>

    <PreferencesComponent prefs={currentUnsavedPreferences} setPrefs={setCurrentUnsavedPreferences} userCanManage={userCanManage}/>
  </CardBody>
  <CardFooter>
    <Button colorScheme='blue' onClick={savePreferences}>Save</Button>
  </CardFooter>
</Card>
</div>
</>
    )
}



const DropdownIndicator = (props: DropdownIndicatorProps<OptionType, true>) => {
  return (
    <components.DropdownIndicator {...props}>
      <EmojiIcon label="Emoji" primaryColor={"grey"} />
    </components.DropdownIndicator>
  );
};


interface PreferenceProps{
    prefs: Preferences
    setPrefs: (v: Preferences)=>void
userCanManage: boolean
}

 function  PreferencesComponent (props: PreferenceProps){
   const [selectBoxOptions, setSelectBoxOptions] = useState<OptionType[]>([])
   const [boardOptions, setBoardOptions] = useState<OptionType[]>([{label:"<No Board>", value: "", key:""}])
    useEffect(() => {
        userApi.getAllTeams().then(value => {
            const teamList: string[] = value.data
            setSelectBoxOptions(teamList.map(value1 => {return {label: value1, value: value1}}))
        })
        api.getBoards().then(value => {

            const boardList: Record<number, string> = value.data.boards
            const newBoardOptions = Object.entries(boardList).map(value1 => {return {label: value1[1], value: value1[0]}})
            setBoardOptions(boardOptions.concat(newBoardOptions))
        })

    }, []);


    function changeBoard(newValue:any, actionMeta: ActionMeta<OptionType>){

        props.setPrefs({...props.prefs, defaultBoard: newValue})
    }
    function addTeam(newValue: any, actionMeta: ActionMeta<OptionType>){
        let data: OptionType[] = newValue;
        const newFavouriteTeams = data.map(value => value.value as string);
        props.setPrefs({...props.prefs, favouriteTeams:newFavouriteTeams})
    }
    const currentTeams: OptionType[] = props.prefs.favouriteTeams.map(value1 => {return {label: value1, value: value1}})

          return (
  <>
      <div style={{width: "250px"}}>
        <FormControl >

            {props.userCanManage && <>
    <Flex className={"mb-2"}>
            <FormLabel htmlFor='isChecked'>Show Teams:</FormLabel>
            <Switch id='isChecked' isChecked={props.prefs.showTeams} onChange={event => props.setPrefs({...props.prefs, showTeams: event.target.checked})}/>
    </Flex>

    <FormLabel htmlFor='indicators-dropdown'>Select Teams:</FormLabel>
    <Select
      inputId="indicators-dropdown"
      closeMenuOnSelect={false}
      components={{ DropdownIndicator }}
      defaultValue={currentTeams}
      isMulti
      options={selectBoxOptions}
      value={props.prefs.favouriteTeams.map(value => {return {label: value, value: value}})}
      onChange={addTeam}
    />
</>}
    <FormLabel htmlFor='board-dropdown' className={"mt-5"}>Select Default Board:</FormLabel>
    <Select
      inputId="board-dropdown"
      closeMenuOnSelect={true}
      components={{ DropdownIndicator }}
      defaultValue={[]}
value={{label: props.prefs.defaultBoard.label, value: props.prefs.defaultBoard.value}}
      onChange={changeBoard}
      options={boardOptions}

    />

    </FormControl>
          </div>
  </>
)}