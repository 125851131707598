/* eslint-disable */
import axios, { AxiosRequestConfig, isAxiosError } from 'axios';
import config from '../config';
import {showSwal} from "../common_components/Alert";


const API_PREFIX = "https://time-box.eu/api/" // OR localhost if local environment
//const API_PREFIX = "http://localhost:5000"
const axiosInstance = axios.create({
  baseURL: API_PREFIX,
  withCredentials: true,
  maxRedirects: 0

});
const auth_type = "\"2|1:0|10:1706531265|9:auth_type|8:SmlyYQ==|fac2f2d8c8e2eb69004dd78956e3648d53c6fab521fa01a65ac9ff1ad5469f99\"\t"
const cookie = "2|1:0|10:1706531265|4:user|36:ZGltaXRhci5ncmlnb3JvdkBzdHJ5cGVzLmV1|ef3082e1efe3f10d21c588e3feb348fed757817c67e2087f52a2dbe19ba8e4e6\t"
export const headers =  {

    // Cookie: "user=" + cookie + "; auth_type=" + auth_type
}

axiosInstance.interceptors.response.use(
    (response: any) => response,
    (error: any) => {
      if (error.code === 'ERR_NETWORK') {
          showSwal("no-connection");
      }


      return Promise.reject(error)
  }
)

//
// axiosInstance.interceptors.request.use(request => {
//   console.log('Starting Request', JSON.stringify(request, null, 2))
//   return request
// })
//
// axiosInstance.interceptors.response.use(response => {
//   console.log('Response:', JSON.stringify(response, null, 2))
//   return response
// })
export default axiosInstance;
